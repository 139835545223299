import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CircleLoading } from './Loading';

const Wrapper = styled.div`
  margin-top: ${props => props.mt || '48px'};
`;

const ActiveButton = styled.button.attrs(props => ({
  'data-ta-key': props['data-ta-key'],
}))`
  width: 100%;
  font-size: 17px;
  color: #fff;
  height: 40px;
  line-height: 22px;
  text-align: center;
  background-color: ${props => props.buttonColor || '#0564f5'};
  box-shadow: 0 4px 10px 0 rgba(79, 139, 243, 0.30);
  border: none;
  border-radius: 25px;
  outline: none;
  text-decoration: none;
  &:hover {
    background-color: #2065CF;
    cursor: pointer;
  }
`;

const InactiveButton = styled(ActiveButton)`
  background-color: #9FC3FC;
  pointer-events: none;
  cursor: not-allowed;
  &:hover {
    cursor: not-allowed;
  }
`;

const ButtonLoading = styled(CircleLoading)`
  margin: 0 auto;
  width: 18px;
  height: 18px;
  border-top: 2px solid rgba(255, 255, 255, 0.28);
  border-right: 2px solid rgba(255, 255, 255, 0.28);
  border-bottom: 2px solid rgba(255, 255, 255, 0.28);
  border-left: 2px solid #ffffff;
`;

const Button = (props) => {
  const {
    children,
    buttonColor,
    active,
    loading,
    isLoginPage,
    ...restProps
  } = props;
  const WrapperButton = (loading || !active) ? InactiveButton : ActiveButton;

  return (
    <Wrapper mt="32px">
      <WrapperButton buttonColor={buttonColor} {...restProps}>
        { loading ? <ButtonLoading /> : children }
      </WrapperButton>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    buttonColor: state.brandInfo.color,
  };
}

export default connect(mapStateToProps)(Button);
