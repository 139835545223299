import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import formatTime from '#utils/formatTime';
import { get } from '#utils/globalVariable';

import Center from '#components/layout/Center';
import Logo from '#components/Logo';
import Input from '#components/Input';
import Button from '#components/Button';
import MsgModal from '#components/MsgModal';
// import RegisterAndPrivacy from '#components/RegisterAndPrivacy';
import { BigPromptTip, MediumPromptTip } from '#components/PromptTip';

import {
  // loginStates,
  setLoginState,
  setNewPassword,
  setNewPasswordAgain,
  setNewPasswordError,
  setNetworkError,
  submitResetPassword,
  handleFatalError,
  ResetPasswordType,
  protocalChanged,
} from '#models/login/action';
import getResetPasswordTip from '#models/login/selectors/getResetPasswordTip';

const messages = defineMessages({
  ruleTip: {
    id: 'ResetPassword.ruleTip',
    defaultMessage: '您的登录密码要符合以下条件：',
  },
  passwordTip: {
    id: 'ResetPassword.passwordTip',
    defaultMessage: '请输入您的新密码',
  },
  passwordAgainTip: {
    id: 'ResetPassword.passwordAgainTip',
    defaultMessage: '请再次输入新密码',
  },
  passwordTimesTip: {
    id: 'ResetPassword.passwordTimesTip',
    defaultMessage: '密码上次已在 {date} 时进行修改。',
  },
  button: {
    id: 'ResetPassword.button',
    defaultMessage: '提交',
  },
});
// const showHeader = !!matchPath(window.location.pathname, {
//   path: '/auc/password-forget',
//   exact: true,
// });

const LogoWrapper = styled.div`
  padding-bottom: 64px;
`;

const ResetPasswordTipWrapper = styled.div`
  margin-bottom: 16px;
`;

const ResetPassword = ({
  newPassword,
  newPasswordAgain,
  buttonLoading,
  newPasswordError,
  passwordRules,
  passwordRulesError,
  passwordTimes,
  networkError,
  fatalError,
  resetPasswordFlag,
  resetPasswordTip,
  onResetPasswordClick,
  onNewPasswordInput,
  onNewPasswordAgainInput,
  onCloseMsgModal,
  onPressEnter,
}) => {
  const showHeader = !!matchPath(window.location.pathname, {
    path: '/auc/password-forget',
    exact: true,
  });
  return (
    <Center
      showHeader={showHeader}
      showFooter={!showHeader}
    >
      {
        !showHeader && get('env') === 'WEB' && (
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        )
      }
      <ResetPasswordTipWrapper>
        <BigPromptTip>
          {resetPasswordTip}
        </BigPromptTip>
      </ResetPasswordTipWrapper>
      <MediumPromptTip>
        {intl.formatMessage(messages.ruleTip)}
        <br />
        {passwordRules}
      </MediumPromptTip>
      <Input
        value={newPassword}
        placeholder={intl.formatMessage(messages.passwordTip)}
        onChange={onNewPasswordInput}
        onKeyUp={onPressEnter}
        maxlength="16"
        type="password"
      />
      <Input
        value={newPasswordAgain}
        placeholder={intl.formatMessage(messages.passwordAgainTip)}
        onChange={onNewPasswordAgainInput}
        onKeyUp={onPressEnter}
        type="password"
        maxlength="16"
        errorMsg={newPasswordError}
      />
      {/* OEM修改项请勿动 */}
      {/* {
        !showHeader && <RegisterAndPrivacy />
      } */}
      <Button
        loading={buttonLoading}
        active={newPassword && newPasswordAgain}
        onClick={onResetPasswordClick}
        isLoginPage
      >
        {intl.formatMessage(messages.button)}
      </Button>
      {
        (passwordTimes && passwordTimes > 0
        && resetPasswordFlag !== ResetPasswordType.FIRST_LOGIN) ? (
          <span style={{ fontSize: '12px', color: '#a4a4a4', paddingLeft: '20px' }}>
            {intl.formatMessage(messages.passwordTimesTip, { date: formatTime(passwordTimes) })}
          </span>
          ) : null
      }
      {/* OEM修改项请勿动 */}
      <MsgModal
        show={
          (!!passwordRulesError)
          || (!!networkError)
          || (!!fatalError)
        }
        msg={
          passwordRulesError
          || networkError
          || fatalError
        }
        onClose={onCloseMsgModal}
      />
    </Center>
  );
};

ResetPassword.propTypes = {
  newPassword: PropTypes.string.isRequired,
  newPasswordAgain: PropTypes.string.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  newPasswordError: PropTypes.string,
  passwordRules: PropTypes.string.isRequired,
  passwordRulesError: PropTypes.string,
  networkError: PropTypes.string,
  passwordTimes: PropTypes.number,
  fatalError: PropTypes.string,
  resetPasswordFlag: PropTypes.string,
  resetPasswordTip: PropTypes.string.isRequired,
  onResetPasswordClick: PropTypes.func.isRequired,
  onNewPasswordInput: PropTypes.func.isRequired,
  onNewPasswordAgainInput: PropTypes.func.isRequired,
  onCloseMsgModal: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func.isRequired,
};

ResetPassword.defaultProps = {
  newPasswordError: null,
  passwordRulesError: null,
  networkError: null,
  passwordTimes: null,
  fatalError: null,
  resetPasswordFlag: '',
};

function mapStateToProps(state) {
  return {
    newPassword: state.newPassword,
    newPasswordAgain: state.newPasswordAgain,
    buttonLoading: state.resetPasswordLoading,
    newPasswordError: state.newPasswordError,
    passwordRules: state.passwordRules,
    passwordRulesError: state.passwordRulesError,
    passwordTimes: state.passwordTimes,
    networkError: state.networkError,
    fatalError: state.fatalError,
    resetPasswordFlag: state.resetPasswordFlag,
    resetPasswordTip: getResetPasswordTip(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onResetPasswordClick() {
      dispatch(submitResetPassword());
      dispatch(protocalChanged(false));
    },
    onNewPasswordInput(e) {
      dispatch(setNewPassword(e.target.value));
    },
    onNewPasswordAgainInput(e) {
      dispatch(setNewPasswordAgain(e.target.value));
    },
    onCloseMsgModal() {
      dispatch(setNewPasswordError(null));
      dispatch(setNetworkError(null));
      dispatch(setLoginState(null));
      dispatch(handleFatalError());
    },
    onPressEnter(e) {
      if (e.keyCode === 13) {
        dispatch(submitResetPassword());
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
