/* eslint-disable */
import React from 'react'
import axios from 'axios'
import queryString from 'query-string'
import styled from 'styled-components'
import { get } from 'lodash'
import intl from 'react-intl-universal'
import { defineMessages } from '#utils/i18n/intl'
import MsgModal from '#components/MsgModal'
import errorIcon from '#static/img/errorIcon.png'
import { fetchEncryptedValues } from '../models/login/LoginService'

const BindWrapper = styled.div`
	height: 100%;
	width: 100%;
`
const HeaderWrapper = styled.div`
	height: 25%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	.title {
		text-align: center;
		font-size: 18px;
	}
	.content {
		margin: 20px 60px;
		text-align: center;
		font-size: 14px;
		color: #454545;
	}
`
const BodyWrapper = styled.div`
	height: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.input {
		height: 40px;
		margin: 80px 30px;
		input {
			margin-top: 20px;
			height: 40px;
			width: 100%;
			font-size: 16px;
			border: none;
			border-bottom: solid;
			border-bottom-color: #e5e5e5;
			border-bottom-width: 1px;
		}
		input::-webkit-input-placeholder {
			/* WebKit browsers */
			color: #b6b6b6;
			font-size: 16px;
		}

		input:-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			color: #b6b6b6;
			font-size: 16px;
		}

		input::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: #b6b6b6;
			font-size: 16px;
		}

		input:-ms-input-placeholder {
			/* Internet Explorer 10+ */
			color: #b6b6b6;
			font-size: 16px;
		}
	}
`
const FooterWrapper = styled.div`
	height: 25%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.button {
		display: flex;
		justify-content: space-between;
		button {
			height: 40px;
			background-color: #0564f5;
			color: white;
			border: none;
			border-radius: 20px;
			width: calc((100% - 90px) / 2);
		}
		button:active {
			opacity: 0.5;
		}
	}
`

const messages = defineMessages({
    prmAccount: {
		id: 'Prm.Account',
		defaultMessage: '账号',
	},
	prmPassword: {
		id: 'Prm.Password',
		defaultMessage: '密码',
	},
    selfBind: {
        id:'Prm.SelfBind',
        defaultMessage:'自助绑定',
    },
    selfBindContent: {
        id:'Prm.SelfBind.Content',
        defaultMessage:'此操作完成后将PRM系统账号与企微上下游账号进行绑定。请按要求填写PRM系统中相关身份信息',
    },
    bindFail:{
        id:'Prm.BindFail',
        defaultMessage:'自助账号绑定未成功，请联系品牌商系统管理员处理',
    },
    bindTips: {
        id:'Prm.BindTips',
        defaultMessage:'请联系品牌商系统管理员开通PRM账号',
    },
    bindNot: {
        id:'Prm.Bind.Not',
        defaultMessage:'未取得PRM账号',
    },
    bind: {
        id:'Prm.Bind',
        defaultMessage:'绑定',
    },
    rebind: {
        id:'Prm.Rebind',
        defaultMessage:'重新绑定',
    },
    close: {
        id:'Prm.Close',
        defaultMessage:'关闭',
    },
	accountNotEmpty: {
		id: 'AccountNotEmpty',
		defaultMessage: '账号不能为空',
	},
	passwordNotEmpty: {
		id: 'Scode.1000002',
		defaultMessage: '密码不能为空',
	},
})
export default class WXWorkPrmBindMain extends React.PureComponent {
	constructor(props) {
		super(props)
		console.log('WXWorkPrmBind:', this.props)
		this.state = {
			showModal: false,
			msgModal: '',
			errorIcon: '',
		}
	}
	componentDidMount() {}
	getTicket = () => {
		const { ticket = '' } = queryString.parse(window.location.search)
		console.log('ticket', ticket)
		return ticket
	}
	gotoPrm = (redirectUrl) => {
		console.log('redirectUrl', redirectUrl)
		if (redirectUrl) {
			window.location.href = redirectUrl
		}
	}
	initHeader = () => {
		return (
			<HeaderWrapper>
				<div className="title">{intl.formatMessage(messages.selfBind)}</div>
				<div className="content">
                {intl.formatMessage(messages.selfBindContent)}
				</div>
			</HeaderWrapper>
		)
	}
	initBody = () => {
		return (
			<BodyWrapper>
				<div className="input">
					<input
						id="wxwork_bind_account"
						type="text"
						placeholder={intl.formatMessage(messages.prmAccount)}
						onChange={(value) => {}}
					/>

					<input
						id="wxwork_bind_password"
						type="password"
						placeholder={intl.formatMessage(messages.prmPassword)}
						onChange={(value) => {}}
					/>
				</div>
			</BodyWrapper>
		)
	}
	bindClick = () => {
		var account = document.getElementById('wxwork_bind_account').value
		var password = document.getElementById('wxwork_bind_password').value
		console.log(account, password)
		if (!account) {
			this.setState({
				showModal: true,
				errorIcon: errorIcon,
				msgModal: intl.formatMessage(messages.accountNotEmpty),
			})
			return
		}
		if (!password) {
			this.setState({
				showModal: true,
				errorIcon: errorIcon,
				msgModal: intl.formatMessage(messages.passwordNotEmpty),
			})
			return
		}
		if (account.length > 200 || password.length > 200) {
			return
		}
		fetchEncryptedValues({
			password,
		}).then(({ password }) => {
			const data = {
				loginName: account,
				password: password,
				ticket: this.getTicket(),
			}
			this.bindRequest(data)
		})
	}
	bindRequest = (data) => {
		axios({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
			method: 'post',
			data: queryString.stringify(data),
			url: '/auc/oauth2/bind-authenticate',
		})
			.then((res) => {
				console.log('res', res)
				if (res.data.scode == 302) {
					this.gotoPrm(get(res, 'data.result.redirectUrl', ''))
				} else {
					console.error(res)
					this.setState({
						showModal: true,
						errorIcon: errorIcon,
						msgModal: intl.formatMessage(messages.bindFail),
					})
				}
			})
			.catch((error) => {
				console.error(error)
				this.setState({
					showModal: true,
					errorIcon: errorIcon,
					msgModal: intl.formatMessage(messages.bindFail),
				})
			})
	}
	bindModal = () => {
		this.setState({
			showModal: true,
			errorIcon: errorIcon,
			msgModal: intl.formatMessage(messages.bindTips),
		})
	}
	initFooter = () => {
		return (
			<FooterWrapper>
				<div className="button">
					<button
						style={{ marginLeft: '30px' }}
						onClick={this.bindModal}
					>
						{intl.formatMessage(messages.bindNot)}
					</button>
					<button
						style={{ marginRight: '30px' }}
						onClick={this.bindClick}
					>
						{intl.formatMessage(messages.bind)}
					</button>
				</div>
			</FooterWrapper>
		)
	}
	render() {
		return (
			<BindWrapper>
				{this.initHeader()}
				{this.initBody()}
				{this.initFooter()}
				<MsgModal
                    closeWord={intl.formatMessage(messages.close)}
					iconSrc={this.state.errorIcon}
                    iconStyle={{ width: '60px', marginTop: '15px' }}
					show={this.state.showModal}
					msg={this.state.msgModal}
					onClose={() => {
						this.setState({
							showModal: false,
						})
					}}
				/>
			</BindWrapper>
		)
	}
}
