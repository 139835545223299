import { takeEvery } from 'redux-saga/effects';
import {
  START_INIT,
  SUBMIT_LOGIN,
  SUBMIT_TENANT,
  SUBMIT_RESET_PASSWORD,
  FETCH_PASSPORT,
  JUMP_TO_LOGIN,
  JUMP_TO_SELECT_TENANT,
  JUMP_TO_RESET_PASSWORD,
  JUMP_TO_FORGET_PASSWORD,
  JUMP_TO_REGISTER,
  JUMP_TO_PRIVACY_POLICY,
  JUMP_TO_VERIFICATION_CODE_HELP_MESSAGE,
  FETCH_VERIFICATION_CODE,
  FEATCH_MSG_VERIFY,
  SUBMIT_VERIFICATION,
  HANDLE_FATAL_ERROR,
  FETCH_TENANT,
  SET_ID,
  MSG_SEND,
} from '../action';
import {
  jumpToForgetPassword,
  jumpToLogin,
  jumpToSelectTenant,
  jumpToPrivacyPolicy,
  jumpToVerificationCodeHelpMessage,
  handleFatalError,
} from '../LoginService';
import fetchInit from './fetchInit';
import fetchLogin from './fetchLogin';
import fetchPassport from './fetchPassport';
import authentication from './authentication';
import qywxAuthen from './qywxAuthen';
import fetchTenant from './fetchTenant';
import fetchResetPassword from './fetchResetPassword';
import jumpToRegister from './jumpToRegister';
import jumpToResetPassword from './jumpToResetPassword';
import fetchVerificationCode from './fetchVerificationCode';
import fetchVerification from './fetchVerification';
import fetchMsgSend from './fetchMsgSend';
import fetchMsgCode from './fetchMsgCode';

export default function* rootSaga(context) {
  yield takeEvery(START_INIT, fetchInit);
  yield takeEvery(SUBMIT_LOGIN, fetchLogin);
  yield takeEvery(FETCH_PASSPORT, fetchPassport);
  yield takeEvery(SUBMIT_TENANT, authentication, context);
  yield takeEvery(SET_ID, qywxAuthen, context);
  yield takeEvery(FETCH_TENANT, fetchTenant);
  yield takeEvery(SUBMIT_RESET_PASSWORD, fetchResetPassword);
  yield takeEvery(JUMP_TO_LOGIN, jumpToLogin, context);
  yield takeEvery(JUMP_TO_SELECT_TENANT, jumpToSelectTenant);
  yield takeEvery(JUMP_TO_RESET_PASSWORD, jumpToResetPassword);
  yield takeEvery(JUMP_TO_FORGET_PASSWORD, jumpToForgetPassword, context);
  yield takeEvery(JUMP_TO_REGISTER, jumpToRegister);
  yield takeEvery(JUMP_TO_PRIVACY_POLICY, jumpToPrivacyPolicy);
  yield takeEvery(JUMP_TO_VERIFICATION_CODE_HELP_MESSAGE, jumpToVerificationCodeHelpMessage);
  yield takeEvery(FETCH_VERIFICATION_CODE, fetchVerificationCode);
  yield takeEvery(FEATCH_MSG_VERIFY, fetchMsgCode);
  yield takeEvery(SUBMIT_VERIFICATION, fetchVerification, context);
  yield takeEvery(MSG_SEND, fetchMsgSend, context); // 短信登录
  yield takeEvery(HANDLE_FATAL_ERROR, handleFatalError, context);
}
