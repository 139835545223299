// src/models/Service.js

import axios from 'axios';
import JSONP from 'jsonp';
import queryString from 'query-string';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';
// import { setTraceId, getTraceId } from '#utils/sentry/utils';
import { status } from './login/LoginService';
import { store } from './login/index';
import {
  setNetworkError,
  setFatalError,
  setLoginLoading,
  setResetPasswordLoading,
  setForgetPasswordLoading,
} from './login/action';
import AvatarMonitor from '#utils/sentry/AvatarMonitor';


const axiosConfig = {
  withCredentials: true,
};
// if (process.env.NODE_ENV !== 'production') {
//   axiosConfig = Object.assign({}, axiosConfig, {
//     baseURL: '/mock',
//   });
// }

const Service = axios.create(axiosConfig);

// 添加请求拦截器：
// 1、改写POST请求体的Content-Type
const formList = [
  '/auc/login',
  '/auc/passport/password-reset',
  '/auc/passport/password-forget-reset',
  '/auc/passport/captcha-fetch',
  '/auc/passport/captcha-check',
  '/auc/smscode/send-captcha',
  '/auc/smscode/auth',
];
Service.interceptors.request.use((config) => {
  if (config.method === 'post' && formList.indexOf(config.url) > -1) {
    // eslint-disable-next-line no-param-reassign
    config.data = queryString.stringify(config.data);

    // eslint-disable-next-line no-param-reassign
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }
  return config;
});

// 2、过滤掉post参数里面NULL
Service.interceptors.request.use((config) => {
  if (config.method === 'post') {
    // eslint-disable-next-line no-param-reassign
    config.data = Object.keys(config.data).reduce((pre, key) => {
      if (config.data[key] !== null) {
        return Object.assign(pre, {
          [key]: config.data[key],
        });
      }
      return pre;
    }, {});
  }
  return config;
});

// 3、添加对于请求错误的处理函数
Service.interceptors.request.use(null, (error) => {
  // // eslint-disable-next-line no-console
  // console.log('request error: ');
  // // eslint-disable-next-line no-console
  // console.dir(error);
  store.dispatch(setNetworkError(error.message));
});

// 添加响应拦截器：
// 1、改写响应格式：
Service.interceptors.response.use((res) => {
  // setTraceId(res);
  AvatarMonitor.getInstance().addListener(res);
  return res.data;
});

// 2、增加对于“严重”级别的网络错误的处理
Service.interceptors.response.use((res) => {
  // console.log('From Service:');
  // 增加对于错误信息的输出
  if (res.scode !== status.OK && res.scode !== status.REDIRECT) {
    console.group('接口请求错误');
    console.error(`错误码：${res.scode}`);
    console.error(`错误信息：${res.error_msg}`);
    console.groupEnd();
  }

  switch (res.scode) {
    case status.INVALID_SESSION:
      store.dispatch(setFatalError(intl.formatMessage(getScodeMessages(res.scode), { errorMsg: res.error_msg })));
      return null;
    default:
      return res;
  }
});

// 3、添加对于响应错误的处理函数
Service.interceptors.response.use(null, (error) => {
  // setTraceId(error.response);
  AvatarMonitor.getInstance().addListener(error.response || error.request);
  store.dispatch(setLoginLoading(false));
  store.dispatch(setResetPasswordLoading(false));
  store.dispatch(setForgetPasswordLoading(false));
  store.dispatch(setNetworkError(intl.formatMessage(getScodeMessages('1000007'))));
  return Promise.reject();
});

export { Service, JSONP };
