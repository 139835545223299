import { jumpToPrivacyPolicy, jumpToRegister } from '#models/login/action';
import isRegisterShown from '#models/login/selectors/isRegisterShown';
import { get } from '#utils/globalVariable';
import { defineMessages } from '#utils/i18n/intl';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import styled from 'styled-components';

const messages = defineMessages({
  clickTip: {
    id: 'RegisterAndPrivacy.clickTip',
    defaultMessage: '点击查看',
  },
  loginPrivacyTip: {
    id: 'RegisterAndPrivacy.loginPrivacyTip',
    defaultMessage: '登录即表明您同意<a href="https://www.xiaoshouyi.com/privacyagreement" target="_blank">《销售易隐私声明》</a>及<a href="https://www.xiaoshouyi.com/usermastersubscriptionagreement" target="_blank">《用户协议》</a>',
  },
  ALogPrivacyTip: {
    id: 'RegisterAndPrivacy.appLoginPrivacyTip',
    defaultMessage: '我已阅读并接受<a href="https://www.xiaoshouyi.com/privacyagreement-app" target="_blank">《销售易隐私声明》</a>及<a href="https://www.xiaoshouyi.com/usermastersubscriptionagreement" target="_blank">《用户协议》</a>',
  },
  registerTip: {
    id: 'RegisterAndPrivacy.registerTip',
    defaultMessage: '免费注册',
  },
});

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.mt};
`;

const PrivacyWrapper = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 22px;
  color: #aaaaaa;
  a{
    color: #0564f5;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
`;

// const PrivacyText = styled.span`
//   padding-left: 4px;
//   text-decoration: ${props => (props.hasUnderline ? 'underline' : 'none')};
//   cursor: ${props => (props.canClick ? 'pointer' : 'auto')};
// `;

const RegisterWrapper = styled.span`
  position: absolute;
  right: 0;
  bottom: -102px;
  display: inline-block;
  width: 68px;
  text-align: right;
  font-size: 12px;
  color: #0564f5;
  line-height: 18px;
  cursor: pointer;
`;

const showRegLink = () => {
  const env = get('env');
  const { hideReg } = queryString.parse(window.location.search);
  if ((env === 'MOBILE' || env === 'WEB') && hideReg === '1') {
    return false;
  }
  if (window.location.href.indexOf('qidian.my') >= 0) {
    return false;
  }
  if (window.location.search && window.location.search.indexOf('ninebot') >= 0) {
    return false;
  }
  return true;
};

// 判断是否要显示注册
const showRegLinkExtend = (state) => {
  const { loginType } = state;
  if (loginType === 'miniapp') {
    return false;
  }
  return true;
};
const getWebTerminalInfo = () => {
  const env = get('env');
  // if (window.location.href.indexOf('qidian.my') >= 0) {
  //   return false;
  // }
  if (env === 'WEB') {
    return true;
  }
  return false;
};
const getAppTerminalInfo = () => {
  const env = get('env');
  if (env === 'MOBILE') {
    return true;
  }
  return false;
};
const RegisterAndPrivacy = ({
  showRegister,
  onRegister,
  onPrivacyPolicy,
  isLoginPage,
  handleChange,
}) => (
  <Wrapper mt={isLoginPage ? '24px' : '32px'}>
    {
      getAppTerminalInfo() && (
        <PrivacyWrapper>
          <input type="checkbox" value="true" onChange={e => handleChange(e)} />
          <div dangerouslySetInnerHTML={{ __html: intl.formatMessage(messages.ALogPrivacyTip) }} />
        </PrivacyWrapper>
      )
    }
    {
      getWebTerminalInfo() && (
        <PrivacyWrapper>
          <div dangerouslySetInnerHTML={{ __html: intl.formatMessage(messages.loginPrivacyTip) }} />
        </PrivacyWrapper>
      )
    }
    {
      showRegister && (
        <RegisterWrapper onClick={onRegister}>
          {intl.formatMessage(messages.registerTip)}
        </RegisterWrapper>
      )
    }
  </Wrapper>
);

RegisterAndPrivacy.propTypes = {
  showRegister: PropTypes.bool.isRequired,
  onRegister: PropTypes.func.isRequired,
  onPrivacyPolicy: PropTypes.func.isRequired,
  isLoginPage: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

RegisterAndPrivacy.defaultProps = {
  isLoginPage: false,
};

function mapStateToProps(state) {
  return {
    showRegister: isRegisterShown(state) && showRegLink() && showRegLinkExtend(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onRegister() {
      dispatch(jumpToRegister());
    },
    onPrivacyPolicy() {
      dispatch(jumpToPrivacyPolicy());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAndPrivacy);
