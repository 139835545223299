/* eslint-disable */
import { call, put } from "redux-saga/effects";
import intl from 'react-intl-universal';
import fetchAuthCode, { authStatus } from "./fetchAuthCode";
import { setPassportError, fetchPassport, setLoginLoading } from "../action";
import getScodeMessages from '#utils/i18n/getScodeMessages';
import { store } from '../index';

export default function* fetchLogin() {
  const state = store.getState();
  const { username, password } = state;

  // 对用户名和密码在本地进行检校
  if (username === '' || password === '') {
    yield put(setPassportError(intl.formatMessage(getScodeMessages(1000004))));
    return;
  }
  // 设置Loading状态
  yield put(setLoginLoading(true));
  try {
    yield put(fetchPassport());
  } catch (error) {
    yield put(setLoginLoading(false))  
  }
}
