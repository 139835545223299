/* eslint-disable */

import { call, put, all } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getAppLocale from '#utils/i18n/getAppLocale';
import getScodeMessages from '#utils/i18n/getScodeMessages';
import queryString from 'query-string';
import {
  status,
  parseQuery,
  getRegisterAddressService,
  getSSOInfoService,
  getBrandInfoService,
} from '../LoginService';
import {
  setMobileParams,
  setHasInitialized,
  setScope,
  setSubDomain,
  setUsername,
  setRegisterAddress,
  setSSOInfo,
  setBrandInfo,
  setRegisterAddressError,
  setSSOError,
  setBrandError,
  setLoginType
} from '../action';

const ELEMENT_TYPE = {
  LOGO: 11,
  PICS: 2,
  COLOR: 3,
  QR_CODE_ANDROID: 6,
  QR_CODE_IOS: 7,
};

export default function* fetchInit() {
  console.log('start');
  const queryParams = yield call(parseQuery, window.location.search);
  console.log(queryParams);
  const appLocale = yield call(getAppLocale);
  console.log(appLocale);
  // 国际化英文环境改变title
  if(appLocale.currentLocale == "en"){
    document.title = "Login | Neocrm";
  }
  console.log(queryString.parse(window.location.search))
  console.log(window.wemeet)
  console.log(window.wx)
  // if (queryParams.mobileParams) {
  //   console.log(queryParams.mobileParams);
  //   const mobileParams = JSON.parse(queryParams.mobileParams);
  //   console.log(mobileParams);
  //   // 设置mobileParams参数
  //   yield put(setMobileParams(mobileParams));
  // }

  // 并发执行以下任务：
  // 1、设置mobileParams参数
  // 2、设置Scope相关信息
  // 3、设置三级域名相关信息
  // 4、设置国际化初始信息
  // 5、设置默认登录用户名
  yield all([
    call(fetchMobileParams, queryParams),
    call(fetchScope, queryParams),
    call(fetchLoginType,queryParams),
    call(fetchSubDomain, queryParams),
    // call(fetchRegisterAddress),
    // call(fetchSSOInfo, queryParams),
    call(fetchBrandInfo, queryParams),
    call(initReactIntl, appLocale),
    call(fetchLoginHint, queryParams),
  ]);

  // 初始化完成，设置标志位
  yield put(setHasInitialized(true));
}

function* fetchMobileParams(queryParams) {
  const { mobileParams } = queryParams;
  if (mobileParams) {
    console.log(mobileParams);
    const mobileParamsJSON = JSON.parse(mobileParams);
    console.log(mobileParamsJSON);
    // 设置mobileParams参数
    yield put(setMobileParams(mobileParamsJSON));
  }
}

function* fetchScope(queryParams) {
  const { scope } = queryParams;
  if (scope) {
    yield put(setScope(scope));
  }
}

function* fetchLoginType(queryParams) {
  const { login_type } = queryParams;
  if (login_type) {
    yield put(setLoginType(login_type));
  }
}

function* fetchSubDomain(queryParams) {
  const { sub_domain: subDomain } = queryParams;
  if (subDomain) {
    yield put(setSubDomain(subDomain));
  }
}

// TODO: 将逻辑转移到jumpToRegister.js文件中
// 避免每次初始化都调用该接口
// function* fetchRegisterAddress() {
//   const res = yield call(getRegisterAddressService);
//   if (res) {
//     switch (res.scode) {
//       case status.OK:
//         yield put(setRegisterAddress(res.result));
//         break;
//       default:
//         yield put(setRegisterAddressError(intl.formatMessage(getScodeMessages(res.scode), {
//           errorMsg: res.error_msg,
//         })));
//         break;
//     }
//   }
// }

// function* fetchSSOInfo(queryParams) {
//   const { ssoId, type } = queryParams;
//   if (ssoId && type) {
//     const res = yield call(getSSOInfoService, { ssoId, type });
//     if (res) {
//       switch (res.scode) {
//         case status.OK:
//           yield put(setSSOInfo(res.result));
//           break;
//         default:
//           yield put(setSSOError(intl.formatMessage(getScodeMessages(res.scode), {
//             errorMsg: res.error_msg,
//           })));
//           break;
//       }
//     }
//     // switch (res.scode) {
//     //   case status.OK:
//     //     yield put(setSSOInfo(res.result));
//     //     break;
//     //   default:
//     //     yield put(setSSOError(intl.formatMessage(getScodeMessages(res.scode), {
//     //       errorMsg: res.error_msg,
//     //     })));
//     //     break;
//     // }
//   }
// }

function* fetchBrandInfo(queryParams) {
  const { sub_domain: subDomain } = queryParams;
  if (subDomain) {
    const res = yield call(getBrandInfoService, { subDomain });
    if (res) {
      switch (res.scode) {
        case status.OK:
        {
          const result = {};
          res.result.batch_data.forEach((data) => {
            switch (data.element_type) {
              case ELEMENT_TYPE.LOGO:
                result.logo = {
                  picture_url: data.element_content,
                };
                break;
              case ELEMENT_TYPE.PICS:
              {
                const list = result.pictures || (result.pictures = []);
                list.push({
                  picture_url: data.element_content,
                  element_refer_url: data.element_refer_url,
                });
                break;
              }
              case ELEMENT_TYPE.COLOR:
                result.color = data.element_content;
                break;
              case ELEMENT_TYPE.QR_CODE_ANDROID:
                if (data.element_content) {
                  result.androidQRcode = data.element_content;
                }
                break;
              case ELEMENT_TYPE.QR_CODE_IOS:
                if (data.element_content) {
                  result.iOSQRcode = data.element_content;
                }
                break;
              default:
                break;
            }
          });

          yield put(setBrandInfo(result));
          break;
        }
        default:
          yield put(setBrandError(intl.formatMessage(getScodeMessages(res.scode), {
            errorMsg: res.error_msg,
          })));
          break;
      }
    }
  }
}

function initReactIntl(appLocale) {
  // return intl.init(appLocale);

  try {
    intl.init(appLocale);
  } catch (error) {
    console.error(error);
    return false;
  }
  return true;
}

function* fetchLoginHint(queryParams) {
  const { login_hint: loginHint } = queryParams;
  if (loginHint) {
    yield put(setUsername(loginHint.trim()));
  }
}
