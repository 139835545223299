import { defineMessages } from '#utils/i18n/intl';

const messages = defineMessages({
  1000000: {
    id: 'Scode.1000000',
    defaultMessage: '系统错误，请联系管理员',
  },
  1000001: {
    id: 'Scode.1000001',
    defaultMessage: '您两次输入的密码不一致，请重新输入。',
  },
  1000002: {
    id: 'Scode.1000002',
    defaultMessage: '密码不能为空',
  },
  1000003: {
    id: 'Scode.1000003',
    defaultMessage: '获取登录密码规则接口错误',
  },
  1000004: {
    id: 'Scode.1000004',
    defaultMessage: '用户名和密码不能为空',
  },
  1000005: {
    id: 'Scode.1000005',
    defaultMessage: '用户名不能为空。',
  },
  1000006: {
    id: 'Scode.1000006',
    defaultMessage: '验证码初始化失败，请刷新页面重试',
  },
  1000007: {
    id: 'Scode.1000007',
    defaultMessage: '无法连接网络，请检查当前设备的网络连接是否正常。',
  },
  1010001: {
    id: 'Scode.1010001',
    defaultMessage: '系统异常，登录失败。',
  },
  1030003: {
    id: 'Scode.1030003',
    defaultMessage: '您操作的租户信息不存在，请联系您的管理员',
  },
  1030004: {
    id: 'Scode.1030004',
    defaultMessage: '您操作的账号信息不存在，请联系您的管理员',
  },
  1030005: {
    id: 'Scode.1030005',
    defaultMessage: '您操作的用户信息不存在，请联系您的管理员',
  },
  2020000: {
    id: 'Scode.2020000',
    defaultMessage: '参数校验异常',
  },
  2020001: {
    id: 'Scode.2020001',
    defaultMessage: '您操作的租户信息不存在，请联系您的管理员',
  },
  2020002: {
    id: 'Scode.2020002',
    defaultMessage: '您操作的帐号不存在，请联系您的管理员',
  },
  2020003: {
    id: 'Scode.2020003',
    defaultMessage: '加密值为空',
  },
  2020004: {
    id: 'Scode.2020004',
    defaultMessage: '您输入的用户名和密码不匹配。',
  },
  2020009: {
    id: 'Scode.2020009',
    defaultMessage: '用户名不能为空。',
  },
  2020011: {
    id: 'Scode.2020011',
    defaultMessage: '您的租户信息有误，请联系管理员。',
  },
  2020013: {
    id: 'Scode.2020013',
    defaultMessage: '验证码错误或已失效，请重试！',
  },
  2030002: {
    id: 'Scode.2030002',
    defaultMessage: '请使用您的租户自定义登录地址登录，详情请与您的租户管理员联系',
  },
  2030007: {
    id: 'Scode.2030007',
    defaultMessage: '验证码错误或已失效，请重试！',
  },
  2030008: {
    id: 'Scode.2030008',
    defaultMessage: '你输入的用户名和密码不匹配。',
  },
  2030009: {
    id: 'Scode.2030009',
    defaultMessage: '您的帐号没有对应任何租户，请联系管理员。',
  },
  2030013: {
    id: 'Scode.2030013',
    defaultMessage: '密码输入长度不够，请重新输入。',
  },
  2030014: {
    id: 'Scode.2030014',
    defaultMessage: '您输入的密码不符合密码安全策略，请重新输入。',
  },
  2030018: {
    id: 'Scode.2030018',
    defaultMessage: '获取系统授权访问令牌（access token）失败。',
  },
  2030023: {
    id: 'Scode.2030023',
    defaultMessage: '请确认您的账号是否被正确授权，如果仍然不能登录请联系您的管理员。',
  },
  2030024: {
    id: 'Scode.2030024',
    defaultMessage: '当前租户已过期，无法登录。',
  },
  2030025: {
    id: 'Scode.2030025',
    defaultMessage: '您的账号已被锁定，请联系系统管理员解除锁定。',
  },
  2030026: {
    id: 'Scode.2030026',
    defaultMessage: '您的账号已被锁定，请联系系统管理员解除锁定。',
  },
  2030027: {
    id: 'Scode.2030027',
    defaultMessage: '您的账号已被锁定，请联系系统管理员解除锁定。',
  },
  2030032: {
    id: 'Scode.2030032',
    defaultMessage: '当前租户client信息出错。',
  },
  2030035: {
    id: 'Scode.2030035',
    defaultMessage: '您操作的账号已被禁用，请联系您的管理员。',
  },
  2030037: {
    id: 'Scode.2030037',
    defaultMessage: '您的账号已经与另外一台设备绑定，无法在当前设备登录。',
  },
  2030038: {
    id: 'Scode.2030038',
    defaultMessage: '请检查您的用户名和密码，如果仍然不能登录，请联系您的PRM管理员。',
  },
  2030039: {
    id: 'Scode.2030039',
    defaultMessage: '获取授权访问令牌（access token）失败。',
  },
  2030044: {
    id: 'Scode.2030044',
    defaultMessage: '您的租户信息有误，请联系管理员。',
  },
  2030051: {
    id: 'Scode.2030051',
    defaultMessage: '您当前访问的地址不存在，请使用有效地址访问系统。',
  },
  2030059: {
    id: 'Scode.2030059',
    defaultMessage: '您输入的账号不存在，请重新输入。',
  },
  2030060: {
    id: 'Scode.2030060',
    defaultMessage: '每日最多可找回五次密码，请明天尝试。',
  },
  2030061: {
    id: 'Scode.2030061',
    defaultMessage: '验证码已过期，请获取新验证码完成验证。',
  },
  2030062: {
    id: 'Scode.2030062',
    defaultMessage: '您输入的验证码错误超过五次，请重新获取新的验证码。',
  },
  2030063: {
    id: 'Scode.2030063',
    defaultMessage: '验证码已发送，如需获取新验证码请等待60秒后重试。',
  },
  2030066: {
    id: 'Scode.2030066',
    defaultMessage: '请检查您的用户名和密码，如果仍然不能登录，请联系您的管理员。',
  },
  2030067: {
    id: 'Scode.2030067',
    defaultMessage: '请检查您的用户名和密码，如果仍然不能登录，请联系您的管理员。',
  },
  2030069: {
    id: 'Scode.2030069',
    defaultMessage: '目前无法使用自定义域名功能。',
  },
  2030070: {
    id: 'Scode.2030070',
    defaultMessage: '请确认您是否有登录CRM的权限，如果仍然不能登录，详情请联系您的CRM管理员。',
  },
  2030071: {
    id: 'Scode.2030071',
    defaultMessage: '请确认您是否有登录PRM的权限，如果仍然不能登录，详情请联系您的PRM管理员。',
  },
  2030072: {
    id: 'Scode.2030072',
    defaultMessage: '您当前的会话长时间处于不活动状态，请刷新页面再进行操作。',
  },
  2030073: {
    id: 'Scode.2030073',
    defaultMessage: '当前APP版本不支持使用自定义域名，请升级到最新版本',
  },
  2030106: {
    id: 'Scode.2030106',
    defaultMessage: '不能重复使用此旧密码',
  },
  2030400: {
    id: 'Scode.2030400',
    defaultMessage: '当前租户已被禁用，如有疑问请联系销售易技术支持团队。',
  },
  2031005: {
    id: 'Scode.2031005',
    defaultMessage: '您的企业未开通企业微信登录功能，请选择其他登录方式。',
  },
  2031006: {
    id: 'Scode.2031006',
    defaultMessage: '您的企业未开通企业微信登录功能，请选择其他登录方式。',
  },
  2031007: {
    id: 'Scode.2031007',
    defaultMessage: '您的企业未开通企业微信登录功能，请选择其他登录方式。',
  },
  2031008: {
    id: 'Scode.2031008',
    defaultMessage: '您的企业未开通企业微信登录功能，请选择其他登录方式。',
  },
  2031009: {
    id: 'Scode.2031009',
    defaultMessage: '您的CRM帐号未与企业微信帐号绑定，请选择其他登录方式。',
  },
  2031010: {
    id: 'Scode.2031010',
    defaultMessage: '您的CRM帐号未与企业微信帐号绑定，请选择其他登录方式。',
  },
  2031011: {
    id: 'Scode.2031011',
    defaultMessage: '您的CRM帐号未与企业微信帐号绑定，请选择其他登录方式。',
  },
  2031028: {
    id: 'Scode.2031028',
    defaultMessage: '您当前访问的单点登录地址不存在，请使用有效地址访问系统。',
  },
  2031044: {
    id: 'Scode.2031044',
    defaultMessage: ' 您当前访问的地址不存在，请使用有效地址访问系统。',
  },
  2031101: {
    id: 'Scode.2031101',
    defaultMessage: '您当前访问的单点登录地址不存在，请使用有效地址访问系统。',
  },
  2031102: {
    id: 'Scode.2031102',
    defaultMessage: '您当前访问的单点登录地址未开通，请使用有效地址访问系统。',
  },
  2031103: {
    id: 'Scode.2031103',
    defaultMessage: '您的CRM账号未与第三方登录账号绑定，请选择其它登录方式。',
  },
  2031104: {
    id: 'Scode.2031104',
    defaultMessage: '您的租户未开通销售易标准登录，请选择其它登录方式。',
  },
  2031105: {
    id: 'Scode.2031105',
    defaultMessage: '您的CRM账号未与第三方登录账号绑定，请选择其它登录方式。',
  },
  2041104: {
    id: 'Scode.2041104',
    defaultMessage: '如果您仍然不能登录，请联系您的系统管理员。',
  },
  2041106: {
    id: 'Scode.2041106',
    defaultMessage: '系统正在升级维护，给您带来的不便敬请谅解！',
  },
  2041107: {
    id: 'Scode.2041107',
    defaultMessage: '请输入一个有效的手机号。',
  },
});

export default function getScodeMessages(scode) {
  return messages[scode] || messages['1000000'];
}
