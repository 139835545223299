import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';

import Center from '#components/layout/Center';
import Logo from '#components/Logo';
import Button from '#components/Button';
import { BigPromptTip, MediumPromptTip } from '#components/PromptTip';

import { jumpToLogin } from '#models/login/action';

const messages = defineMessages({
  notFound: {
    id: 'Error404.notFound',
    defaultMessage: '抱歉，无法找到该页！',
  },
  errorTip1: {
    id: 'Error404.errorTip1',
    defaultMessage: '您正在访问的页面可能已经删除、更名或暂时不可用。 请确认网址拼写是否正确或稍后尝试再次刷新。',
  },
  errorTip2: {
    id: 'Error404.errorTip2',
    defaultMessage: '如有问题，请致电我们免费客服热线：4000-500-907',
  },
  getBack: {
    id: 'Error404.getBack',
    defaultMessage: '返回',
  },
});

const LogoWrapper = styled.div`
  padding-bottom: 64px;
`;

const NotFoundWrapper = styled.div`
  margin-bottom: 16px;
`;

const ErrorTip = styled(MediumPromptTip)`
  color: #6A7280;
`;

const Error404 = ({ onGetBackClick }) => (
  <Center>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <NotFoundWrapper>
      <BigPromptTip>
        {intl.formatMessage(messages.notFound)}
      </BigPromptTip>
    </NotFoundWrapper>
    <ErrorTip>
      {intl.formatMessage(messages.errorTip1)}
      <br />
      <br />
      {intl.formatMessage(messages.errorTip2)}
    </ErrorTip>
    <Button
      active
      onClick={onGetBackClick}
    >
      {intl.formatMessage(messages.getBack)}
    </Button>
  </Center>
);

Error404.propTypes = {
  onGetBackClick: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    onGetBackClick() {
      dispatch(jumpToLogin());
    },
  };
}

export default connect(null, mapDispatchToProps)(Error404);
