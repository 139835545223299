import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ScrollArea from 'react-scrollbar';
import { get } from '#utils/globalVariable';
import CancelBounceEffect from '#utils/cancelBounceEfffect';

const WrapperScrollArea = styled.div`
  max-height: ${({ env }) => (env === 'MOBILE' ? '100%' : '385px')};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  & .scrollbar-container.vertical:hover {
      background: none;
      opacity: .1;
  }
`;

// export default function ScrollList(props) {
//   const { dataList, children } = props;
//   return (
//     <WrapperScrollArea
//       horizontal={false}
//       verticalScrollbarStyle={{
//         borderRadius: '4px',
//       }}
//       smoothScrolling
//     >
//       {dataList.map(data => children({ data }))}
//     </WrapperScrollArea>
//   );
// }

export default class ScrollList extends PureComponent {
  constructor(props) {
    super(props);
    this.scrollBox = null;
    this.cancal = null;
  }

  componentDidMount() {
    // let startY = 0;
    // document.body.addEventListener('touchstart', (e) => {
    //   startY = e.touches[0].pageY;
    // }, { passive: false });

    // document.body.addEventListener('touchmove', (e) => {
    //   // eslint-disable-next-line prefer-destructuring
    //   const scrollBox = this.scrollBox;
    //   const moveY = e.touches[0].pageY;
    //   const top = scrollBox.scrollTop;
    //   const ch = scrollBox.clientHeight;
    //   const sh = scrollBox.scrollHeight;
    //   if (!isChildTarget(e.target, scrollBox)) {
    //     e.preventDefault();
    //   } else if ((top === 0 && moveY > startY) || (top + ch === sh && moveY < startY)) {
    //     e.preventDefault();
    //   }
    // }, { passive: false });

    // function isChildTarget(child, parent) {
    //   if (child && parent) {
    //     while (child) {
    //       if (parent === child) {
    //         return true;
    //       }
    //       // eslint-disable-next-line no-param-reassign
    //       child = child.parentNode;
    //     }
    //   }
    //   return false;
    // }
    // 取消IOS平台的橡皮筋效果
    this.cancel = new CancelBounceEffect(this.scrollBox);
    // this.cancel = new CancelBounceEffect();
    this.cancel.start();
  }

  componentWillUnmount() {
    this.cancel.destroy();
  }

  render() {
    const { dataList, children } = this.props;
    const env = get('env');
    return (
      <WrapperScrollArea env={env} ref={(el) => { this.scrollBox = el; }}>
        <ScrollArea
          horizontal={false}
          verticalScrollbarStyle={{
            borderRadius: '4px',
          }}
          smoothScrolling
        >
          {dataList.map(data => children({ data }))}
        </ScrollArea>
      </WrapperScrollArea>
    );
  }
}

ScrollList.propTypes = {
  dataList: PropTypes.array.isRequired,
};
