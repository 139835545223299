import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logoZh from '#static/img/new-register-logo.png';
import logoEn from '#static/img/logo-en.png';
import logoMobile from '#static/img/logo_mobile.png';
import { get } from '#utils/globalVariable';
import { connect } from 'react-redux';


// let imgHeight = navigator.language === 'zh-CN' ? '38px' : '28px';
const Img = styled.img`
  height: ${props => props.imgHeight};
`;
// let logoWeb = navigator.language === 'zh-CN' ? logoZh : logoEn;

const getImgHeight = () => {
  return navigator.language.indexOf('zh') !== -1 ? '38px' : '28px';
};

const getLogoWeb = () => {
  return navigator.language.indexOf('zh') !== -1 ? logoZh : logoEn;
};
const Logo = ({ imgSrc }) => {
  const defaultLogoImg = get('env') === 'WEB' ? getLogoWeb() : logoMobile;
  return (
    <Img src={imgSrc || defaultLogoImg} imgHeight={getImgHeight()} />
  );
};

Logo.propTypes = {
  imgSrc: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    imgSrc: state.brandInfo.logo.picture_url,
  };
}

export default connect(mapStateToProps)(Logo);
