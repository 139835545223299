import React from 'react';
import styled from 'styled-components';
import SuccessIconSrc from '#static/img/success.svg';

const IconWrapper = styled.img`
  display: block;
  margin: 0 auto 24px;
`;

export default () => (
  <IconWrapper src={SuccessIconSrc} />
);
