import { createSelector } from 'reselect';

const getById = state => state.tenantList.byId;

export default createSelector(
  getById,
  (byId) => {
    let selectedTenant = {};
    Object.keys(byId).some((id) => {
      if (byId[id].selected) {
        selectedTenant = { ...byId[id] };
        return true;
      }
      return false;
    });
    return selectedTenant;
  },
);
