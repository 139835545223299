/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { defineMessages } from '#utils/i18n/intl';
import intl from 'react-intl-universal';

const Wrapper = styled.div`
  position: relative;
  .type_button {
    color: #1b4dc2;
    margin-top: ${props => (props.errorMsg ? '25px': '10px')};
    font-size: 14px;
    line-height: 14px;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    z-index: 5;
    border: solid 1px #979797;
    width: 110px;
    height: 95px;
    margin-top: 5px;
    font-size: 14px;
    line-height: 14px;
  }
  a {
    display: block;
    margin-top: 15px;
    margin-left: 13px;
    color: #1b4dc2;
    height: 30px;
  }
  a[active="active"] {
    color: #979797;
  }
`;

const messages = defineMessages({
  phoneSelect: {
    id: 'Phone.check',
    defaultMessage: '手机验证',
  },
  emailSelect: {
    id: 'Email.check',
    defaultMessage: '邮箱验证',
  },
  selectSelect: {
    id: 'Select.select',
    defaultMessage: '使用其他验证方式',
  },
});

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  state = {
    currentIndex:0
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props || nextState.currentIndex !== this.state.currentIndex
  }

  componentDidMount() {
    document.body.addEventListener('click',function(){
      let showContent = document.querySelector(".dropdown-content");
      showContent.style["display"] == "block"
        && (showContent.style["display"] = "none")
  })
  }
  showMenu() {
    let showContent = document.querySelector(".dropdown-content");
    showContent.style["display"] == "block"
      ? (showContent.style["display"] = "none")
      : (showContent.style["display"] = "block");
  }
  onClick(element,index){
    const { onChange} = this.props
    onChange && onChange(element)
    this.changeSubmit(index)
    this.setState({currentIndex:index})
  }
  changeSubmit(index){
    const {currentIndex} = this.state
    let className =  ['.firstChild' , '.secondChild']
    let hiddenSubmitInput = document.querySelector(className[currentIndex])
    let showSubmitInput = document.querySelector(className[index])
    hiddenSubmitInput&&(hiddenSubmitInput.style["display"] = "none")
    showSubmitInput&&(showSubmitInput.style["display"] = "block")
  }
  getSelect() {
   const { data } = this.props;
   const {currentIndex} = this.state
   let menuList = data.map((element)=>{
    let name = element.type=='1'?intl.formatMessage(messages.phoneSelect):intl.formatMessage(messages.emailSelect)
    return name
    
   })
  //  console.log(menuList,'menuList===');
    return data.map((element,index) => {
      let status = index == currentIndex?'active':'unactive'
      return <a active={status} key={index} onClick={() => this.onClick(element,index)}>
        {menuList[index]}
      </a>;
      });
  }

  render() {
    const {errorMsg,data} = this.props
    console.log(123);
    return (
      <Wrapper className="select_render" errorMsg={errorMsg}>
        <div onClick={this.showMenu} className="type_button">
          {intl.formatMessage(messages.selectSelect)}
        </div>
        <div id="myDropdown" className="dropdown-content">
          {this.getSelect()}
        </div>
      </Wrapper>
    );
  }
}
