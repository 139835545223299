/* eslint-disable */
import iconCancel from "#static/img/icon_cancel.svg";
import icoClose from "#static/img/icon_close.svg";
import iconInfo from "#static/img/icon_info.svg";
import { get } from "#utils/globalVariable";
import AvatarMonitor from '#utils/sentry/AvatarMonitor';
import React from "react";
import styled from "styled-components";

const TipsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
  height: ${(props) => (props.type == "info" ? 0 : "100vh")};

  .show-tips {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid;
    font-size: 14px;
    position: fixed;
    top: 16px;
    display: flex;
    align-items: center;
    border-color: ${(props) => (props.type == "info" ? "#00CA8D" : "#FF3939")};
    background: ${(props) => (props.type == "info" ? "#E5FBF4" : "#FFF5F5")};
    color: ${(props) => (props.type == "info" ? "#182437" : "#FF3939")};
    z-index: 9999;

    &__icon-wrapper {
      margin-right: 12px;
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: ${(props) => (props.type == "info" ? "#AFF2DF" : "#ffd7da")};
    }

    &__content {
      width: ${(props) => (props.isMobile ? "200px" : "unset")};
    }

    &__icon {
      width: 13px;
    }

    &__close-btn {
      margin-left: 20px;
      width: 18px;
      cursor: pointer;
    }
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  componentDidMount = () => {
    const { clearTips, autoClose } = this.props;
    if (autoClose) {
      setTimeout(() => {
        this.handleClose(), clearTips && clearTips();
      }, 3000);
    }
    AvatarMonitor.getInstance().report();
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    const { type = "info", message, closeable } = this.props;
    const { show } = this.state;
    if (!show) {
      return null;
    }
    return (
      <TipsContainer type={type} isMobile={get("env") === "MOBILE"}>
        <div className="show-tips">
          <div className="show-tips__icon-wrapper">
            <img
              className="show-tips__icon"
              src={type === "info" ? iconInfo : iconCancel}
            />
          </div>
          <div className="show-tips__content">{message}</div>
          {closeable && (
            <img
              className="show-tips__close-btn"
              src={icoClose}
              onClick={this.handleClose}
            />
          )}
        </div>
      </TipsContainer>
    );
  }
}
