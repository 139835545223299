/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import Carousel from "#components/Carousel";
import Image from "#components/Image";
import backgroundImage from "#static/img/forget-password-background.png";
import error404Image from "#static/img/error-404.png";
import { get } from "#utils/globalVariable";
import WXWorkError from "#components/WXWorkError";
import QYWXTenant from '#components/qywxTenant';
import SSOError from "#components/SSOError";
import WemeetMain from "#components/WemeetMain";
import DingtalkMain from "#components/DingtalkMain";
import WXWorkPrmBindMain from "#components/WXWorkPrmBind";
import WemeetResult from "#components/WemeetResult";
import FeishuMain from "#components/FeishuMain";
import { loginStates } from '#models/login/action';

const { AUTHENTICATION } = loginStates;

const Main = styled.main`
  background: ${(props) =>
    props.backgroundImg
      ? `bottom/cover url(${props.backgroundImg})`
      : "#FFFFFF"};
  height: 100%;
  overflow: hidden;
  min-height: ${(props) => props.isWeb ? "670px" : "auto"};
`;

const LoginSection = styled.section`
  width: auto;
  height: 100%;
  overflow: auto;
  @media (max-width: 832px) {
    width: 100%;
    max-width: 100%;
  }
`;

const MobileLoginSection = styled.section`
  height: 100%;
`;

const CarouselSection = styled.section`
  position: relative;
  float: left;
  width: 32%;
  height: 100%;
  @media (max-width: 832px) {
    display: none;
  }
`;

const ErrorLeftSection = styled.section`
  position: relative;
  float: left;
  width: 41.67%;
  height: 100%;
  @media (max-width: 832px) {
    width: 100%;
    max-width: 100%;
  }
`;

const ErrorRightSection = styled.section`
  /* flex: 1; */
  width: auto;
  height: 100%;
  overflow: hidden;
  @media (max-width: 832px) {
    display: none;
  }
`;

export default (props) => {
  const { status, children } = props;
  const env = get("env");
  switch (env) {
    case "WEB":
      return (
        <Switch>
          <Route path="/auc/wxwork/multi-tenant" exact component={QYWXTenant}></Route> 
          <Route path="/auc/sso-error" exact component={SSOError} />
          <Route path="/auc/wemeet-main" exact component={WemeetMain} />
          <Route path="/auc/wemeet-result" exact component={WemeetResult} />
          <Route path="/auc/wxwork/to-bind" exact component={WXWorkPrmBindMain} />
          <Route path="/neologin/dingtalk/workbench" exact component={DingtalkMain} />
          <Route path="/auc/wxwork-error" exact component={WXWorkError} />
          <Route path="/auc/feishu/authz/entrance" exact component={FeishuMain} />
          <Route
            path="/auc/password-forget"
            exact
            render={() => (
              <Main backgroundImg={backgroundImage}>
                <MobileLoginSection>{children}</MobileLoginSection>
              </Main>
            )}
          />
          <Route
            render={() => (
              <Main isWeb={true}>
                {
                  status !== AUTHENTICATION ? (
                    <CarouselSection>
                      <Switch>
                        <Route
                          path={["/auc/oauth2/auth", "/"]}
                          exact
                          component={Carousel}
                        />
                        <Route render={() => <Image imgSrc={error404Image} />} />
                      </Switch>
                    </CarouselSection>
                  ) : null
                }
                <LoginSection>{children}</LoginSection>
              </Main>
            )}
          />
        </Switch>
      );
    case "MOBILE":
      return (
        <Main>
          <MobileLoginSection>{children}</MobileLoginSection>
        </Main>
      );
    case "ERROR":
      return (
        <Main>
          <ErrorLeftSection>{children}</ErrorLeftSection>
          <ErrorRightSection>
            <Image imgSrc={error404Image} />
          </ErrorRightSection>
        </Main>
      );
    case "PODRELEASE":
      return (
        <Main>
          {children}
        </Main>
      );
    default:
      return <Main>ERROR</Main>;
  }
};
