/* eslint-disable */
import axios from "axios";
import md5 from "md5";
import { getEnv, getOsType } from "./utils";
import getScodeMessages from "#utils/i18n/getScodeMessages";

// interface AOptions {
//   osType?: PropTypes.string; // 操作系统类型 Android  iOS  macOs  Windows
//   // domain?: string; // 域名
//   // pod?: string; // pod集群环境
//   platform?: PropTypes.string; // 终端类型 mobile web h5 这是必填字段
//   url?: PropTypes.string; // 上报地址
//   reportFlag?: PropTypes.bool; // 是否开启上报
//   frame?: PropTypes.string; // 框架类型 dayone | neo
// };

const REPORT_URL = "https://avatar-data.ingageapp.com/api/avatar/log/info"; // 默认上报接口
const CONFIG_URL = "https://avatar-data.ingageapp.com/api/avatar/log/config"; // 默认是否开启接口
const APP_SECRET = "avatar_app_secret";
const APP_API_VERSION = "v1.0";

class AvatarMonitor {
  static Instance;
  config;
  events = []; // 暂存异常信息
  manual; // 手动开关

  constructor() {
    this.config = {
      reportFlag: true, // 默认开启上报
      url: REPORT_URL, // 上报地址
      osType: getOsType(),
      pod: getEnv(),
    };
    this.events = [];
    this.manual = true;
  }

  static getInstance() {
    if (!AvatarMonitor.Instance) {
      AvatarMonitor.Instance = new AvatarMonitor();
    }
    return AvatarMonitor.Instance;
  }

  init = (options = {}) => {
    Object.assign(this.config, options);
    this.isEnable();
    this.open();
  };

  setOptions = (options = {}) => {
    Object.assign(this.config, options);
  };

  // 是否开启上报
  isEnable = () => {
    try {
      axios.get(CONFIG_URL).then((res) => {
        if (
          res &&
          res.status == 200 &&
          res.data &&
          res.data.data &&
          res.data.data.reportFlag != undefined
        ) {
          this.config.reportFlag = res.data.data.reportFlag;
          if (!this.config.reportFlag) {
            this.close();
          }
        }
      });
    } catch (err) {
      console.warn("avatarErr======", err);
    }
  };

  // 添加监听
  addListener = (res = {}) => {
    try {
      if (!this.config.reportFlag || !this.manual) {
        return;
      }
      if (res.data) {
        let responseCode =
          res.data.scode != undefined
            ? res.data.scode
            : res.data.status != undefined
            ? res.data.status
            : res.data.code != undefined
            ? res.data.code
            : res.status || "";
        if (responseCode != 0 && responseCode != 200) {
          const scodeMessages = getScodeMessages(responseCode);
          const errorMessage =
            (scodeMessages && scodeMessages.defaultMessage) ||
            res.error_msg ||
            "";
          this.addEvent(responseCode, res, errorMessage);
        }
      } else {
        this.addEvent(res.status || "", res, "no response");
      }
    } catch (err) {
      console.warn("avatarErr======", err);
    }
  };

  // 手动开启上报，如果是服务器返回了关闭，那手动开启也没用
  open = () => {
    if (this.config.reportFlag) {
      this.manual = true;
    }
  };

  // 添加异常事件到Map里
  addEvent = (errorCode, res, errorMessage) => {
    try {
      const domain = window.location.host;
      const log = {
        domain,
        osType: this.config.osType,
        responseBody: JSON.stringify(res.data) || res.responseText,
        pod: this.config.pod,
        platform: this.config.platform,
        module: "login",
        operationType: "login",
        timestamp: Date.now(),
        errorCode,
        errorMessage,
        pageUrl: window.location.href,
        actionUrl:
          (res.request && res.request.responseURL) ||
          res.responseURL ||
          res.url,
        actionTraceId:
          (res.headers && res.headers["cat-root-messageid"]) ||
          (res.getResponseHeader &&
            res.getResponseHeader("cat-root-messageid")),
        extContent: {
          apiKey: "login",
        },
      };
      this.events.push(log);
      console.log("avatarErr======log", log);
    } catch (err) {
      console.warn("avatarErr======", err);
    }
  };

  // 上报
  report = () => {
    try {
      if (!this.config.reportFlag || !this.manual) {
        return;
      }

      if (this.events.length == 0) {
        return;
      }
      const url = this.config.url;
      const body = JSON.stringify({
        signParam: this.getSign(),
        logs: this.events.slice(),
      });
      axios({
        method: "post",
        url,
        headers: { "Content-Type": "application/json" },
        data: body,
      });

      this.events = [];
    } catch (err) {
      console.warn("avatarErr======", err);
    }
  };

  // 获取签名，上报参数
  getSign = () => {
    try {
      const appId = "avatar_log";
      const timestamp = Date.now();
      const nonce = Math.random()
        .toString()
        .split(".")[1];
      const sign = md5(
        appId + APP_SECRET + timestamp + nonce + APP_API_VERSION
      );
      return {
        appId,
        timestamp,
        nonce,
        sign,
      };
    } catch (err) {
      console.warn("avatarErr======", err);
      return {};
    }
  };

  // 关闭上报
  close = () => {
    this.manual = false;
  };
}

export default AvatarMonitor;
