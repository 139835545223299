/* eslint-disable */
import React from "react";
import Carousel from "#components/Carousel";
import styled from "styled-components";
import queryString from "query-string";
import SelectTenant from '#pages/SelectTenant';
import { Service } from "#models/Service";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    qywxlist,
  } from '#models/login/action';
const Main = styled.main`
  background: ${(props) =>
        props.backgroundImg
            ? `bottom/cover url(${props.backgroundImg})`
            : "#FFFFFF"};
  height: 100%;
  overflow: hidden;
  min-height: ${(props) => props.isWeb ? "670px" : "auto"};
`;
class QYWXTenant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
        };
    }
    componentDidMount() {
        this.test()
    }
    test = async () => {
        const { code } = queryString.parse(window.location.search);
        const res = await Service.get(`/auc/wxwork/tenant-list?code=${code}`)
        if(res.scode!=="200") {
            return window.location.replace(`${window.location.protocol}//${window.location.host}/auc/wxwork-error?error_code=${res.scode}`)
        }
        this.setState({
            list: res.result.tenants
        })
        this.props.getQYWXList(this.state.list)
    }
    render() {
        return (
            <Main>
                <SelectTenant ide dataList={this.state.list}></SelectTenant>
            </Main>
        )
    }
};
QYWXTenant.propTypes = {
    dataList: PropTypes.arrayOf(
        PropTypes.shape({
            list: PropTypes.array,
        }),
    ).isRequired,
    getQYWXList: PropTypes.func.isRequired,
};
function mapStatesToProps(state) {
}
function mapDispatchToProps(dispatch) {
    return {
        getQYWXList(val) {
            dispatch(qywxlist(val))
        },
    };
}
export default connect(
    mapStatesToProps,
    mapDispatchToProps,
)(QYWXTenant);