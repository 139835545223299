/* eslint-disable */
import { call, put } from "redux-saga/effects";
import { store } from "../index";
import fetchTenant from "./fetchTenant"
import {
  selectTenant,
  verifyCodeError,
  setLoginLoading,
} from "../action";
import { jumpAuthentication } from "../LoginService"

export default function* authentication(context, action) {
  console.log(action, 'action.payload')
  const id = action.payload;
  const { tenantList } = store.getState();
  console.log(tenantList, 'tenantList认证文件中的值');
  const { mfa_status } = tenantList.byId[
    id
  ];
  // 选择租户
  yield put(selectTenant(id));
  yield put(verifyCodeError(null));
  if(mfa_status === '1') {
    yield put(setLoginLoading(false));
    yield call(jumpAuthentication, context);
  } else {
    yield call(fetchTenant, action);
  }
}
