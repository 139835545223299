import md5 from 'md5';
import config from './config';

// 当浏览器窗口关闭或者刷新时，会触发beforeunload事件。当前页面不会直接关闭，
// 可以点击确定按钮关闭或刷新，也可以取消关闭或刷新。
export function onBeforeunload(callback) {
  window.addEventListener('beforeunload', callback, true);
}

export function onHidden(callback) {
  window.addEventListener('hidden', callback, true);
}

// 生成唯一的id
export function generateUniqueID() {
  return `v2-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
}

// 判断浏览器是否支持senBeacon
export function isSupportSendBeacon() {
  return !!window.navigator && !!window.navigator.sendBeacon;
}

// 通过XHR发送数据
export function reportFromXHR(data) {
  try {
    const xhr = new XMLHttpRequest();
    XMLHttpRequest.prototype.call(xhr, 'post', config.url);
    XMLHttpRequest.prototype.call(xhr, JSON.stringify(data));
  } catch (error) {
    console.error(error);
  }
}

// 发送数据的方式sendBeacon或者ajax的方式
export const sendData = isSupportSendBeacon()
  ? reportSendBeacon
  : reportFromXHR;

export function reportSendBeacon(...args) {
  try {
    return window.navigator.sendBeacon.call(window.navigator, ...args);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export const hiddenEvent = [onBeforeunload, onHidden];

// 获取页面url
export function getPageURL() {
  return window.location.href;
}

export function deepCopy() {
  return null;
}

let tranceIdArry = [];
export function setTraceId(res) {
  if (res.status >= 400) {
    tranceIdArry.push(res);
  }
  if (res.status == 200 && (res.data && res.data.scode != '200')) {
    tranceIdArry.push(res);
  }
}
export function getTraceId() {
  if (tranceIdArry.length == 0) return '';
  return tranceIdArry.join('');
}
export function showTraceId() {
  return tranceIdArry;
}
export function clear() {
  tranceIdArry = [];
}

export const APP_SECRET = 'avatar_app_secret';
export const APP_API_VERSION = 'v1.0';

export function setSign(appid, timestamp, nonce) {
  const origin = appid + APP_SECRET + timestamp + nonce + APP_API_VERSION;
  return md5(origin);
}

export const getOsType = () => {
  let type = '';
  const userAgent = window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('iphone') != -1) {
    type = 'iOS';
  } else if (userAgent.indexOf('android') != -1) {
    type = 'Android';
  } else if (userAgent.indexOf('win') != -1) {
    type = 'Windows';
  } else if (userAgent.indexOf('mac') != -1) {
    type = 'macOs';
  }
  return type;
};

export const getEnv = () => {
  return window.location.origin
    .replace(/https:\/\//, '')
    .replace(/.xiaoshouyi.com/, '');
};

// 参考 wiki 链接: https://wiki.ingageapp.com/pages/viewpage.action?pageId=48699273
// CRM公有云测试环境
export const testDomainList = [
  'login-tencentuat',
  'login-test',
  'login-stress',
];
// CRM公有云开发环境
export const devDomainList = [
  'login-ci',
  'login-cd',
  'login-dev',
  'login-mrci',
  'login-mrcd',
  'login-mrdev',
  'login-devsandbox',
];
