import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import Logo from '#components/Logo';
import {
  Wrapper,
  LogoWrapper,
  ButtonWrapper,
  Button,
} from '#components/Header';

const messages = defineMessages({
  goHome: {
    id: 'ForgetPassword.Header.goHome',
    defaultMessage: '回到首页',
  },
});


const Header = ({
  onSignIn,
}) => (
  <Wrapper>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <ButtonWrapper>
      <Button type="button" onClick={onSignIn}>{intl.formatMessage(messages.goHome)}</Button>
    </ButtonWrapper>
  </Wrapper>
);

Header.propTypes = {
  onSignIn: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    onSignIn() {
      window.location.reload();
    },
  };
}

export default connect(null, mapDispatchToProps)(Header);
