/* eslint-disable */
import { call, put } from "redux-saga/effects";
import queryString from 'query-string';
import {
  setLoginLoading
} from "../action";
import {
  getQYWXTenant,
} from "../LoginService";
import { store } from "../index";
import { get } from '#utils/globalVariable';

export default function* qywxFetchTenant(action) {
  console.log(action, 'action操作----------------------');
  console.log(queryString.parse(window.location.search).code, 'queryString.parse(window.location.search');
  const {code} = queryString.parse(window.location.search)
  const id = action.payload;
  console.log(store.getState(), "fetchTenant文件控制台打印");
  const env = get('env')
  // 发起请求
  const para = {
    code:code,
    login_type: env==="WEB"?'web':'mobile',
    tenant_id: id
  }
  console.log(para, 'para参数-------------------')
  try {
    const res = yield call(getQYWXTenant, para);
    console.log(res, 'new值认证接口');
    if (!res) {
      yield put(setLoginLoading(false));
      return;
    }
    // if (res.scode!=='200') {
    //    return window.location.replace(`${window.location.protocol}//${window.location.host}/auc/sso-error`)
    // }
  } catch (error) {
    yield put(setLoginLoading(false));
  }
}
