import { call, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';
import {
  ResetPasswordType,
  setVerificationCode,
  setForgetPasswordLoading,
  setForgetPasswordError,
  setSecureCode,
  setPassport,
  jumpToResetPassword,
} from '../action';
import {
  checkVerificationCode,
  status,
} from '../LoginService';
import { store } from '../index';

export default function* fetchVerification() {
  const state = store.getState();
  let { forgetPasswordReceiver, verificationCode, username } = state;
  username = (forgetPasswordReceiver == null || forgetPasswordReceiver == undefined || forgetPasswordReceiver == '') ? username : forgetPasswordReceiver;
  // 设置Loading状态
  yield put(setForgetPasswordLoading(true));

  // 发起短信验证码检校请求
  try {
    const res = yield call(checkVerificationCode, {
      username,
      verificationCode,
    });
    // 清空Loading状态
    yield put(setForgetPasswordLoading(false));
    if (res) {
      switch (res.scode) {
        case status.OK:
        {
          // 检校短信验证码成功
          // 设置账户信息与secureCode
          const { secureCode, passportId } = res.result;
          yield put(setSecureCode(secureCode));
          yield put(setPassport(passportId));
          // 清空忘记密码页错误
          yield put(setForgetPasswordError(null));
          // 清空验证码
          yield put(setVerificationCode(''));
          // 跳转到重置密码页面
          yield put(jumpToResetPassword({ resetPasswordFlag: ResetPasswordType.FORGET_PASSWORD }));
          break;
        }
        default:
          // 短信验证码检校失败，设置错误
          yield put(
            setForgetPasswordError(
              intl.formatMessage(
                getScodeMessages(res.scode),
                { errorMsg: res.error_msg },
              ),
            ),
          );
          break;
      }
    }
  } catch (err) {
    // console.log(err);
  }
}
