import styled from 'styled-components';

const PromptTip = styled.div`
  color: #666666;
  text-align: left;
`;

export const BigPromptTip = styled(PromptTip)`
  font-size: 24px;
  font-weight: 600;
  color: #263142;
`;


export const MediumPromptTip = styled(PromptTip)`
  font-size: 14px;
`;
