import { createSelector } from 'reselect';

const getById = state => state.tenantList.byId;
const getAllIds = state => state.tenantList.allIds;

export default createSelector(
  getById,
  getAllIds,
  (byId, allIds) => allIds.map(id => byId[id]),
);
