import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import Center from '#components/layout/Center';
import Logo from '#components/Logo';
import Button from '#components/Button';
import { BigPromptTip, MediumPromptTip } from '#components/PromptTip';
import SuccessIcon from './components/SuccessIcon';

import { jumpToLogin } from '#models/login/action';

const messages = defineMessages({
  successTip: {
    id: 'ResetPasswordSuccessfully.successTip',
    defaultMessage: '重置密码成功',
  },
  timingTip: {
    id: 'ResetPasswordSuccessfully.timingTip',
    defaultMessage: '{time}秒后自动跳转登录页，',
  },
  jumpTip: {
    id: 'ResetPasswordSuccessfully.jumpTip',
    defaultMessage: '如系统没有自动跳转请点击下方',
  },
  backTip: {
    id: 'ResetPasswordSuccessfully.backTip',
    defaultMessage: '返回登录页',
  },
});

// const showHeader = !!matchPath(window.location.pathname, {
//   path: '/auc/password-forget',
//   exact: true,
// });

const LogoWrapper = styled.div`
  padding-bottom: 64px;
`;

const ResetSuccessTipWrapper = styled.div`
  margin-bottom: 16px;
`;

const CenterBigPromptTip = styled(BigPromptTip)`
  text-align: center;
`;

const CenterMediumPromptTip = styled(MediumPromptTip)`
  text-align: center;
`;

class ResetPasswordSuccessfully extends PureComponent {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      remainingTime: 3,
    };
    this.timeTask = () => {
      this.timer = setTimeout(() => {
        this.setState(preState => ({ remainingTime: preState.remainingTime - 1 }));
      }, 1000);
    };
  }

  componentDidMount() {
    this.timeTask();
  }

  componentDidUpdate(prevProps, prevState) {
    const { remainingTime } = prevState;
    if (remainingTime > 0) {
      this.timeTask();
    } else {
      this.onJumpToLoginPageClick();
    }
  }

  componentWillUnmount() {
    this.timeTask = null;
    this.timer = null;
  }

  onJumpToLoginPageClick = () => {
    const { jumpToLoginPage } = this.props;
    clearTimeout(this.timer);
    jumpToLoginPage();
  }

  render() {
    const { onJumpToLoginPageClick } = this;
    const { remainingTime } = this.state;
    const showHeader = !!matchPath(window.location.pathname, {
      path: '/auc/password-forget',
      exact: true,
    });
    return (
      <Center
        showHeader={showHeader}
        showFooter={!showHeader}
      >
        {/* {
          !showHeader && (
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          )
        } */}
        <SuccessIcon />
        <ResetSuccessTipWrapper>
          <CenterBigPromptTip>
            {intl.formatMessage(messages.successTip)}
          </CenterBigPromptTip>
        </ResetSuccessTipWrapper>
        <CenterMediumPromptTip>
          {intl.formatMessage(messages.timingTip, { time: remainingTime })}
          <br />
          {intl.formatMessage(messages.jumpTip)}
        </CenterMediumPromptTip>
        <Button
          active
          onClick={onJumpToLoginPageClick}
        >
          {intl.formatMessage(messages.backTip)}
        </Button>
      </Center>
    );
  }
}

ResetPasswordSuccessfully.propTypes = {
  jumpToLoginPage: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    jumpToLoginPage() {
      dispatch(jumpToLogin());
    },
  };
}

export default connect(null, mapDispatchToProps)(ResetPasswordSuccessfully);
