import { call, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';
import {
  setForgetPasswordError,
  setNECaptchaValidate,
  setIsVerificationCodeLoading,
  setForgetPasswordReceiver,
  setForgetPasswordShowTips,
  setForgetPasswordTips,
} from '../action';
import {
  getVerificationCode,
  popupAuthCode,
  status,
} from '../LoginService';
import { store } from '../index';

export default function* fetchVerificationCode() {
  const state = store.getState();
  const {
    username,
    NECaptchaValidate,
  } = state;

  // 对用户名进行检校
  if (username === '') {
    yield put(setForgetPasswordError(intl.formatMessage(getScodeMessages(1000005))));
    return;
  }

  try {
    // 设置接口请求状态
    // yield put(setIsVerificationCodeLoading(true));
    // 请求获取短信验证码的接口
    const res = yield call(getVerificationCode, {
      username,
      NECaptchaValidate,
    });

    // 清空NECaptchaValidate
    // 保证只有call(popupAuthCode)时才会获取最新的NECaptchaValidate
    // 其他请求不带有NECaptchaValidate
    yield put(setNECaptchaValidate(null));

    if (res) {
      switch (res.scode) {
        case status.OK:
          // 获取短信验证码成功
          // 1、清空忘记密码页错误
          // 2、根据结果判断是否需要显示图片识别码
          yield put(setForgetPasswordError(null));
          if (res.result.needCode === 1) {
            // const error = yield call(popupAuthCode);
            // if (error) {
            //   yield put(setForgetPasswordError(intl.formatMessage(getScodeMessages(1000006))));
            // }
            try {
              yield call(popupAuthCode);
            } catch (error) {
              yield put(setForgetPasswordError(intl.formatMessage(getScodeMessages(1000006))));
            }
          } else if (res.result.needCode === 0) {
            let encodeUsername = '';
            if (res.result.loginName.indexOf('@') >= 0) {
              encodeUsername = res.result.loginName.replace(/(.{2}).*(@).*(.{2}\.com)/, '$1******$2*$3');
            } else {
              encodeUsername = res.result.loginName.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
            }
            yield put(
              setForgetPasswordTips(
                intl.formatMessage({
                  id: 'ForgetPassword.receiver',
                  defaultMessage: '您的验证码已成功发送至{receiver}，请查收!',
                }, { receiver: encodeUsername }),
              ),
            );
            yield put(setForgetPasswordReceiver(res.result.loginName));
            yield put(setForgetPasswordShowTips(true));
            // 设置接口请求状态
            yield put(setIsVerificationCodeLoading(true));
          }
          break;
        case status.RESET_PASSWORD_7:
          if (res.result) {
            yield put(setForgetPasswordReceiver(res.result.loginName));
          }
          yield put(
            setForgetPasswordError(
              intl.formatMessage(
                getScodeMessages(res.scode),
                { errorMsg: res.error_msg },
              ),
            ),
          );
          break;
        default:
          yield put(
            setForgetPasswordError(
              intl.formatMessage(
                getScodeMessages(res.scode),
                { errorMsg: res.error_msg },
              ),
            ),
          );
          break;
      }
    }
  } catch (err) {
    console.log(err);
  }
}
