/* eslint-disable */
import { call, put } from "redux-saga/effects";
import intl from "react-intl-universal";
import getScodeMessages from "#utils/i18n/getScodeMessages";
import { setPassportError } from "../action";
import { needAuthCode, popupAuthCode, status } from "../LoginService";
import { store } from "../index";

export const authStatus = {
  SHOW: "SHOW",
  HIDE: "HIDE",
  ERROR: "ERROR",
  NULL: "NULL"
};

export function* fetchCode(res) {
  // 请求成功,清空username和password的错误信息
  yield put(setPassportError(null));
  switch (res.result) {
    case 1:
      try {
        yield call(popupAuthCode);
        return authStatus.SHOW;
      } catch (error) {
        yield put(
          setPassportError(intl.formatMessage(getScodeMessages(1000006)))
        );
        return authStatus.ERROR;
      }
    case 0:
      return authStatus.HIDE;

    default:
      return authStatus.NULL;
  }
}

export default function* fetchAuthCode() {
  const { username } = store.getState();
  try {
    const res = yield call(needAuthCode, { username });
    if(!res){
      return authStatus.ERROR;
    }
    if (res.scode === status.OK) {
      return fetchCode(res)
    } else {
      // 请求失败
      yield put(
        setPassportError(
          intl.formatMessage(getScodeMessages(res.scode), {
            errorMsg: res.error_msg
          })
        )
      );
      return authStatus.ERROR;
    }
  } catch (error) {
    return authStatus.ERROR;
  }
}
