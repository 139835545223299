const toDouble = (num) => {
  if (typeof num !== 'number') {
    return num;
  }
  return num < 10 ? `0${num}` : num;
};

const formatTime = (timestamp) => {
  try {
    const date = new Date(timestamp);
    const Y = date.getFullYear();
    const M = toDouble(date.getMonth() + 1);
    const D = toDouble(date.getDate());
    const h = toDouble(date.getHours());
    const m = toDouble(date.getMinutes());
    const s = toDouble(date.getSeconds());
    const str = `${Y}-${M}-${D} ${h}:${m}:${s}`;
    return str;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export default formatTime;
