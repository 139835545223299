import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reducers, { initialState } from './reducer';
import rootSaga from './sagas/index';

export default (context) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducers, initialState, composeWithDevTools(
    applyMiddleware(sagaMiddleware),
  ));
  sagaMiddleware.run(rootSaga, context);
  return store;
};
