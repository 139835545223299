import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import { get } from '#utils/globalVariable';
import { jumpToLogin, jumpToRegister, setVerificationCode } from '#models/login/action';
import Logo from '#components/Logo';
import {
  Wrapper,
  LogoWrapper,
  ButtonWrapper,
  Tip,
  Button,
} from '#components/Header';

const messages = defineMessages({
  hasAccount: {
    id: 'ForgetPassword.Header.hasAccount',
    defaultMessage: '记住密码',
  },
  signIn: {
    id: 'ForgetPassword.Header.signIn',
    defaultMessage: '直接登录',
  },
  noAccount: {
    id: 'ForgetPassword.Header.noAccount',
    defaultMessage: '没有账户?',
  },
  signUp: {
    id: 'ForgetPassword.Header.signUp',
    defaultMessage: '注 册',
  },
});

// const Wrapper = styled.div`
//   overflow: hidden;
//   height: 100%;
//   display: flex;
//   justify-content: space-between;
// `;

// const LogoWrapper = styled.div`
// `;

// const ButtonWrapper = styled.div`
//   line-height: 32px;
// `;

// const Tip = styled.span`
//   padding: 0 12px;
//   font-size: 12px;
//   color: #9DA5B3;
// `;

// const Button = styled.button`
//   background: #FFFFFF;
//   border: 1px solid #4E80F5;
//   border-radius: 4px;
//   font-size: 12px;
//   color: #4E80F5;
//   width: 80px;
//   height: 32px;
//   cursor: pointer;
// `;

const FirstTip = styled(Tip)`
  padding-left: 0px;
`;

const Header = ({
  onSignIn,
  onRegister,
}) => (
  <Wrapper>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <ButtonWrapper>
      <FirstTip>{intl.formatMessage(messages.hasAccount)}</FirstTip>
      <Button type="button" onClick={onSignIn}>{intl.formatMessage(messages.signIn)}</Button>
      {/* <Tip>{intl.formatMessage(messages.noAccount)}</Tip>
      <Button type="button" onClick={onRegister}>{intl.formatMessage(messages.signUp)}</Button> */}
    </ButtonWrapper>
  </Wrapper>
);

Header.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    onSignIn() {
      dispatch(jumpToLogin());
      dispatch(setVerificationCode(''));
    },
    onRegister() {
      dispatch(jumpToRegister());
      dispatch(setVerificationCode(''));
    },
  };
}

export default connect(null, mapDispatchToProps)(Header);
