import { createSelector } from 'reselect';

const getScope = state => state.scope;

export default createSelector(
  getScope,
  (scope) => {
    switch (scope) {
      case 'prm':
        return false;
      default:
        return true;
    }
  },
);
