import axios from 'axios';

// eslint-disable-next-line import/no-mutable-exports
let config = {
  url: 'https://avatar-dev.ingageapp.com/api/avatar/log/info',
  reportFlag: true,
};

export default config;

export function setConfig(options) {
  axios.get('https://avatar-dev.ingageapp.com/api/avatar/log/config').then((res) => {
    if (res.status == 200) {
      const { data = {} } = res;
      config.reportFlag = data.reportFlag;
    }
  });
  // for (const key in config) {
  //   if (options[key]) {
  //     config[key] = options[key];
  //   }
  // }
  Object.assign(config, options);
}
