import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import chrome from '#static/img/chrome1.png';
import firefox from '#static/img/firefox1.png';
import safari from '#static/img/safari1.png';
import close from '#static/img/close.png';

const Wrapper = styled.div`
  width: 680px;
  height: 400px;
  border-radius: 8px;
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-340px;
  margin-top:-200px;
  background:#FFF;
  z-index:9999;
  text-align:center;
  padding-top: 45px;
  .close-icon{
    position: absolute;
    right: 20px;
    top: 20px;
    text-align: right;
    img{
      cursor: pointer;
    }
  }
  .browser_title{
    display: block;
    height: 28px;
    line-height: 28px;
    color: rgba(41, 41, 41, 1);
    font-size: 28px;
    text-align: center;
    font-family: PingFangSC-Medium;
    margin-top:36px;
  }
  .point_text{
    height: 16px;
    line-height: 16px;
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
    text-align: center;
    margin: 24px 0 40px;
    font-family: PingFangSC-Regular;
  }
  .browser_list{
  }
  .browser_item{
    text-decoration: none;
    position: relative;
    width: 200px;
    height: 140px;
    display: inline-block;
    float: left;
    margin-left: 23px;
    p{
      position: absolute;
      text-align: center;
      width: 100%;
      color: #666666;
      top: 120px;
      margin: 0;
      span{
        display: inline-block;
        width: 40px;
        height: 20px;
        line-height: 20px;
        border-radius: 10px;
        background-color: rgba(102, 158, 255, 1);
        color: #fff;
        font-size: 12px;
        margin-left: 4px;
      }
    }
    .browser_img{
      width: 80px;
      height: 80px;
      border: none;
    }
  }
  .browser_item:hover img {
    width: 96px;
    height: 96px;
  }
  .browser_item:hover p {
    color: #292929;
  }
`;

ReactModal.setAppElement('#modal-slot');

class BrowserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
    };
  }
  handleCloseModal = () => {
    this.setState({
      isShow: false,
    });
  }
  render() {
    const { isShow } = this.state;
    const { ieVersion } = this.props;
    return (
      <ReactModal
        isOpen={isShow}
        style={{
          overlay: {
            zIndex: 101,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            padding: 0,
            backgroundColor: 'rgba(255, 255, 255, 0)',
            border: 'none',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
          },
        }}
      >
        <Wrapper>
          { ieVersion === 11 && <div className="close-icon"><img src={close} onClick={this.handleCloseModal} /></div> }
          <span className="browser_title">请使用推荐浏览器</span>
          <p className="point_text">
            为了更好的安全性和浏览体验，请点击下列图标，下载浏览器
          </p>
          <div className="browser_list">
            <a className="browser_item" target="_blank" href="http://www.firefox.com.cn/">
              <img className="browser_img" src={firefox} />
              <p>Firefox</p>
            </a>
            <a className="browser_item" target="_blank" href="https://www.google.cn/chrome/">
              <img className="browser_img" src={chrome} />
              <p>
                Chrome
                <span>最佳</span>
              </p>
            </a>
            <a className="browser_item" target="_blank" href="https://pc.qq.com/search.html#!keyword=Safari">
              <img className="browser_img" src={safari} />
              <p>Safari</p>
            </a>
          </div>
        </Wrapper>
      </ReactModal>
    );
  }
}

BrowserModal.propTypes = {
  ieVersion: PropTypes.number,
};

BrowserModal.defaultProps = {
  ieVersion: null,
};
export default BrowserModal;
