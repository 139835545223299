/* eslint-disable */
import androidIcon from "#static/img/Android.svg";
import iOSIcon from "#static/img/IOS.svg";
import androidDefaultQRcode from "#static/img/andriod_QRcode.png";
import filingIcon from "#static/img/filing.png";
import iOSDefaultQRcode from "#static/img/ios_QRcode.png";
import { defineMessages } from "#utils/i18n/intl";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import React from "react";
import intl from "react-intl-universal";
import { connect } from "react-redux";
import styled from "styled-components";

const messages = defineMessages({
  or: {
    id: "Footer.or",
    defaultMessage: "或"
  },
  download: {
    id: "Footer.download",
    defaultMessage: "点击此处下载"
  },
  iosDownload: {
    id: "Footer.iosDownload",
    defaultMessage: "扫描下载iOS客户端"
  },
  androidDownload: {
    id: "Footer.androidDownload",
    defaultMessage: "扫描下载安卓客户端"
  },
  filingCode: {
    id: "Footer.filingCode",
    defaultMessage: "京公网安备 11010502035449号"
  }
});

// 是P10环境，不显示公安备案
const isP10 = location.hostname === 'login-p10.xiaoshouyi.com'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;
const SystemWrapper = styled.div`
  display: flex;
`

const TmpToolTip = ({ className, children, ...props }) => (
  <Tooltip overlayClassName={className} {...props}>
    {children}
  </Tooltip>
);
const TooltipWrapper = styled(TmpToolTip)`
  &.rc-tooltip {
    width: 194px;
    background-color: #ffffff;
  }
  & .rc-tooltip-inner {
    border: none;
    box-shadow: 0 0 6px 0 rgba(25, 31, 37, 0.16);
    border-radius: 6px;
    border: 1px solid rgba(25, 31, 37, 0.08);
  }
  & .rc-tooltip-arrow {
    border-width: 0 !important;
    border-color: transparent !important;
    border-style: none;
    width: 19px;
    height: 10px;
    overflow: hidden;
    bottom: -8px !important;
  }
  & .rc-tooltip-arrow:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: -6px;
    left: 3px;
    transform: rotate(45deg);
    background: #ffffff;
    box-shadow: 1px 1px 6px -1px rgba(25, 31, 37, 0.16);
    border: 1px solid rgba(25, 31, 37, 0.08);
  }
`;

const Button = styled.button`
  display: block;
  width: 78px;
  height: 32px;
  margin-right: 12px;
  padding-left: 29px;
  border: none;
  border-radius: 16px;
  font-size: 12px;
  color: #aaaaaa;
  line-height: 16px;
  background-color: #f8f9fc;
  position: relative;
  ::before{
    content: url('${props => props.icon}');
    position: absolute;
    top: 6px;
    left: 8px;
  }
`;

const Filing = styled.a`
  display: block;
  margin-top: 9px;
  padding-left: 25px;
  font-size: 12px;
  color: #aaaaaa;
  position: relative;
  ::before{
    content: url('${filingIcon}');
    position: absolute;
    left: 0px;
    top: -1px;
  }
`;

const OverlayWrapper = styled.div`
  width: 176px;
  padding: 10px 0;
  background: #ffffff;
`;

const QRcode = styled.img`
  height: 152px;
  width: 152px;
  margin: 0 auto;
  display: block;
`;

const OverlayPrompt = styled.div`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  color: ${props => (props.textColor ? props.textColor : "#3d5266")};
  text-align: center;
`;

const OverlayPromptLink = styled.a`
  color: #0564f5;
`;

const TipButton = ({ imgSrc, promptText, promptLink, showLink, children }) => {
  const Overlay = (
    <OverlayWrapper>
      <QRcode src={imgSrc} />
      <OverlayPrompt fontSize="12px" textColor="rgba(25,31,37,0.72)">
        {promptText}
      </OverlayPrompt>
      {/* {showLink && (
        <OverlayPrompt fontSize="12px" textColor="rgba(25,31,37,0.72)">
          {intl.formatMessage(messages.or)}
          <OverlayPromptLink
            href={promptLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(messages.download)}
          </OverlayPromptLink>
        </OverlayPrompt>
      )} */}
    </OverlayWrapper>
  );

  return (
    <TooltipWrapper
      align={{
        offset: [0, -13]
      }}
      trigger={["hover"]}
      placement="top"
      overlay={Overlay}
      destroyTooltipOnHide={false}
    >
      {children}
    </TooltipWrapper>
  );
};

TipButton.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  promptText: PropTypes.string.isRequired
};

const Footer = ({ iOSQRcode, androidQRcode }) => (
  <Wrapper>
    {/* oem处理，勿动 */}
    {/*注释注释start */}
    <SystemWrapper
      style={{
        width: isP10 ? '100%' : 'auto',
        justifyContent: isP10 ? 'space-around' : 'normal'
      }}
    >
      <TipButton
        imgSrc={iOSQRcode}
        promptText={intl.formatMessage(messages.iosDownload)}
        promptLink="https://itunes.apple.com/cn/app/ingage-crm/id654720925?ls=1&mt=8"
        showLink={iOSQRcode === iOSDefaultQRcode}
      >
        <Button icon={iOSIcon}>iPhone</Button>
      </TipButton>
      <TipButton
        imgSrc={androidQRcode}
        promptText={intl.formatMessage(messages.androidDownload)}
        promptLink="https://xsystatic.s3.cn-north-1.amazonaws.com.cn/app/xiaoshouyi.apk"
        showLink={androidQRcode === androidDefaultQRcode}
      >
        <Button icon={androidIcon}>Android</Button>
      </TipButton>
    </SystemWrapper>
    {/*注释注释end */}
    {
      !isP10 &&
      <Filing
      href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502035449"
      target="_blank"
      rel="noopener noreferrer"
    >
      {intl.formatMessage(messages.filingCode)}
    </Filing>
    }
  </Wrapper>
);

export default connect(state => ({
  iOSQRcode: state.brandInfo.iOSQRcode,
  androidQRcode: state.brandInfo.androidQRcode
}))(Footer);
