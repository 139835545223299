import React from 'react';
import queryString from 'query-string';

const loadFeishuSdk = (setErrMessage) => {
  const feishuScript = document.createElement('script');
  feishuScript.src = 'https://lf1-cdn-tos.bytegoofy.com/goofy/lark/op/h5-js-sdk-1.5.16.js';
  feishuScript.type = 'text/javascript';
  feishuScript.onload = () => {
    try {
      const queryParams = queryString.parseUrl(window.location.href, { decode: false });
      const neoAuthNUriQueryParams = queryString.parseUrl((queryParams.query && queryParams.query.neoAuthNUri) || '');
      if (!neoAuthNUriQueryParams.url || !neoAuthNUriQueryParams.query) {
        setErrMessage(`Invalid neoAuthNUri,${window.location.href}`);
        return;
      }
      if (!window.h5sdk) {
        setErrMessage(`Invalid Lark h5sdk property,${feishuScript.src}`);
        return;
      }
      const appId = (neoAuthNUriQueryParams && neoAuthNUriQueryParams.appId) || (typeof queryParams.query.appId === 'object' ? queryParams.query.appId[0] : queryParams.query.appId);
      window.h5sdk.ready(() => {
        window.tt.requestAuthCode({
          appId,
          success: (info) => {
            const resultUrl = `${neoAuthNUriQueryParams.url}?${queryString.stringify({
              code: info.code,
              ...((neoAuthNUriQueryParams && neoAuthNUriQueryParams.query) || {}),
            })}`;
            // window.prompt('复制', resultUrl);
            window.location.href = resultUrl;
          },
          fail: (error) => {
            setErrMessage(`requestAuthCode failed,${error.errString},${window.location.href}`);
            console.error(error);
          },
        });
      });
    } catch (err) {
      setErrMessage(`认证失败,${window.location.href}`);
      console.error('认证失败', err);
    }
  };
  document.body.appendChild(feishuScript);
  return () => {
    document.body.removeChild(feishuScript);
  };
};

class FeishuMain extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
  }
  componentDidMount() {
    loadFeishuSdk((errorMessage) => {
      this.setState({
        errorMessage,
      });
    });
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <React.Fragment>
        {errorMessage}
      </React.Fragment>
    );
  }
}
export default FeishuMain;
