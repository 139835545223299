/* eslint-disable */
import {
  call,
  put
} from "redux-saga/effects";
import intl from "react-intl-universal";
import getScodeMessages from "#utils/i18n/getScodeMessages";
import queryString from 'query-string';
import {
  ResetPasswordType,
  setFatalError,
  verifyCodeError,
  jumpToResetPassword,
  setLoginLoading
} from "../action";
import {
  getTenantService,
  getSmsService,
  getBFFService,
  getTenantSuccess,
  redirectSelfPage,
  status
} from "../LoginService";
import {
  store
} from "../index";

export default function* fetchTenant(action) {
  console.log(action, 'action操作----------------------');
  console.log(queryString.parse(window.location.search).code, 'queryString.parse(window.location.search');
  const id = action.payload;
  const code = action.code;
  console.log(store.getState(), "fetchTenant文件控制台打印");
  const {
    passport,
    tenantList,
    mobileParams
  } = store.getState();
  const passportId = passport;
  const {
    encryptionKey,
    id: tenantId,
    user_type: userType
  } = tenantList.byId[
    id
  ];
  let  mfaType = action.mfa_type
  // 发起请求
  const params = {
    userType,
    encryptionKey,
    tenantId,
    passportId,
    mobileParams,
    mfaType
  };
  if (code) {
    params.mobileParams.mfa_captcha = code;
  }
  try {
    const {
      smsenabled,
      passwordenabled
    } = queryString.parse(window.location.search);
    const control = sessionStorage.getItem("controlStatus")
    let result
    if (smsenabled == 1 && passwordenabled == 1) {
      result = control == 1
    } else if (passwordenabled == 1) {
      result = passwordenabled == 1
    } else if (smsenabled == 1) {
      result = smsenabled !== "1"
    } else {
      result = passwordenabled == undefined||passwordenabled==0
    }
    // 如果result为true则请求账号密码选租户接口
    const res = result === true ? yield call(getTenantService, params): yield call(getSmsService, params)
    switch (res.scode) {
      case status.REDIRECT:
        // 选择租户请求成功
        switch (res.result.type) {
          case 1:
            // 1、使用JSONP调用BFF接口
            try {
              const bffRes = yield call(getBFFService, res.result.redirectUrl);
              if (!bffRes) {
                yield put(setLoginLoading(false));
                return;
              }
              if (bffRes.scode === status.OK) {
                yield call(getTenantSuccess, bffRes.result);
              } else {
                // 登录请求失败
                // 1、设置请求错误信息
                yield put(
                  setFatalError(
                    intl.formatMessage(getScodeMessages(bffRes.scode), {
                      errorMsg: bffRes.error_msg
                    })
                  )
                );
                yield put(setLoginLoading(false));
              }
            } catch (error) {
              yield put(setLoginLoading(false));
            }
            break;
          case 2:
            // 2、跳转到系统页面
            yield call(redirectSelfPage, res.result.redirectUrl);
            break;
          default:
            console.error("Invalid param:'type'.");
            yield put(setLoginLoading(false));
        }
        break;
      case status.AUTHCODE:
        yield put(setLoginLoading(false));
        yield put(
          verifyCodeError(
            intl.formatMessage(getScodeMessages(res.scode), {
              errorMsg: res.error_msg
            })
          )
        )
        break;
      default:
        // 登录请求失败
        // 1、设置请求错误信息
        yield put(
          setFatalError(
            intl.formatMessage(getScodeMessages(res.scode), {
              errorMsg: res.error_msg
            })
          )
        );
        yield put(setLoginLoading(false));
        break;
    }
  } catch (error) {
    yield put(setLoginLoading(false));
  }
}