/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import createSagaMiddleware from 'redux-saga';
import { withRouter } from 'react-router-dom';
// import reducers, { initialState } from './reducer';
// import rootSaga from './sagas/index';
import configureStore from './configureStore';

// eslint-disable-next-line import/no-mutable-exports
let store;

const ProviderWithRouter = withRouter(
  ({ history, children }) => {
    if (!store) {
      store = configureStore({
        routerHistory: history,
      });
    }
    return (
      <Provider store={store}>
        {children}
      </Provider>
    );
  },
);

// const sagaMiddleware = createSagaMiddleware();
// const store = createStore(reducers, initialState, composeWithDevTools(
//   applyMiddleware(sagaMiddleware),
// ));
// sagaMiddleware.run(rootSaga);

// export default store;
export { ProviderWithRouter, store };
