// src/models/login/sagas/fetchMsgSend.js

import {
  call,
  put,
} from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';

import {
  setVerificationCode,
  setForgetPasswordLoading,
  setForgetPasswordError,
  createTenantList,
  submitTenant,
  jumpToSelectTenant,
  setLoginLoading,
  setPassport,
  setPassportError,
  setTenantError,
} from '../action';
import {
  checkMsgSend,
  status,
} from '../LoginService';
import {
  store,
} from '../index';

export default function* fetchMsgSend() {
  const state = store.getState();
  const {
    smsName,
    smsvificationCode,
  } = state;

  // 设置Loading状态
  yield put(setForgetPasswordLoading(true));
  // 发起短信验证码检校请求
  try {
    // const params = queryString.parse(window.location.search);
    const res = yield call(checkMsgSend, {
      smsName,
      smsvificationCode,
      // ...params,
    });
    // 清空Loading状态
    yield put(setForgetPasswordLoading(false));
    if (!res) {
      yield put(setLoginLoading(false));
      return;
    }
    if (res) {
      switch (res.scode) {
        case status.OK: {
          // 获取短信登录后的租户列表数据
          // 触发action 促使reducer数据更新
          yield put(setPassport(res.result.passport_id));
          yield put(setPassportError(null));
          yield put(setTenantError(null));
          yield put(createTenantList(res.result.tenant_list));
          localStorage.setItem('smsName', smsName);
          const tenantsNumber = res.result.tenant_list.length;
          if (tenantsNumber === 1) {
            yield put(submitTenant(res.result.tenant_list[0].id));
          } else if (tenantsNumber > 1) {
            yield put(jumpToSelectTenant());
            yield put(setLoginLoading(false));
          } else {
            console.error("There's no tenants for this passport.");
            yield put(setLoginLoading(false));
          }
          // 清空验证码
          yield put(setVerificationCode(''));
          break;
        }
        default:
          // 短信验证码检校失败，设置错误
          yield put(
            setForgetPasswordError(
              intl.formatMessage(
                getScodeMessages(res.scode), {
                  scode: res.scode,
                },
              ),
            ),
          );
          break;
      }
    }
  } catch (err) {
    // console.log(err);
  }
}
