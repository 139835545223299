// class CancelBounceEffect {
//   startY = 0

//   start() {

//   }
// }

// export default CancelBounceEffect;
let listener = [];

let startY = 0;
document.body.addEventListener('touchstart', (e) => {
  startY = e.touches[0].pageY;
}, { passive: false });

document.body.addEventListener('touchmove', (e) => {
  // eslint-disable-next-line prefer-destructuring
  const target = e.target;
  const moveY = e.touches[0].pageY;

  if (listener.length > 0) {
    // 首先，遍历所有监听实例，
    // 若所有监听实例都不包含e.target，则阻止滚动
    // 若存在监听实例包含e.target，则找出该实例
    // let index = null;
    // listener.forEach((item, i) => {
    //   if (item.targetIsChild(target)) {
    //     index = i;
    //   }
    // });
    // if (index === null) {
    //   e.preventDefault();
    // } else {
    //   const instance = listener[index];

    //   // 判断该实例是否处在滚动的边缘
    //   // 若处于边缘，则阻止滚动
    //   // 若不在边缘，则允许滚动
    //   if (instance.isOnTheEdge({
    //     target,
    //     startY,
    //     moveY,
    //   })) {
    //     e.preventDefault();
    //   }
    // }

    // 首先，遍历目标节点的所有祖先节点，
    // 找到距离目标节点最近的实例
    const instance = findTargetParent(e.target);

    if (instance === null) {
      e.preventDefault();
    } else {
      // 判断该实例是否处在滚动的边缘
      // 若处于边缘，则阻止滚动
      // 若不在边缘，则允许滚动
      instance.isOnTheEdge({
        target,
        startY,
        moveY,
      }) && e.preventDefault();
    }
  }
  // // 首先，遍历所有监听实例，
  // // 若所有监听实例都不包含e.target，则阻止滚动
  // // 若存在监听实例包含e.target，则找出该实例
  // let index = null;
  // listener.some((item, i) => {
  //   if (item.targetIsChild(target)) {
  //     index = i;
  //     return true;
  //   }

  //   return false;
  // });
  // if (index === null) {
  //   e.preventDefault();
  // } else {
  //   const instance = listener[index];

  //   // 判断该实例是否处在滚动的边缘
  //   // 若处于边缘，则阻止滚动
  //   // 若不在边缘，则允许滚动
  //   if (instance.isOnTheEdge({
  //     target,
  //     startY,
  //     moveY,
  //   })) {
  //     e.preventDefault();
  //   }
  // }
}, { passive: false });

// function shouldMove({
//   target,
//   scrollBox,
//   // eslint-disable-next-line no-shadow
//   startY,
//   moveY,
// }) {
//   const top = scrollBox.scrollTop;
//   const ch = scrollBox.clientHeight;
//   const sh = scrollBox.scrollHeight;

//   if (!isChildTarget(target, scrollBox)) {
//     return false;
//   }

//   if ((top === 0 && moveY > startY) || (top + ch === sh && moveY < startY)) {
//     return false;
//   }

//   return true;
// }

function findTargetParent(target) {
  let child = target;
  let parent = null;
  while (child) {
    // if (parent === child) {
    //   return true;
    // }
    listener.some((item) => {
      if (item.scrollBox && child === item.scrollBox) {
        parent = item;
        return true;
      }
      return false;
    });
    // eslint-disable-next-line no-param-reassign
    child = child.parentNode;
  }
  return parent;
}

export default class CancelBounceEffect {
  constructor(scrollBox) {
    this.scrollBox = scrollBox;
  }

  start() {
    listener.push(this);
  }

  destroy() {
    listener = listener.filter(item => (item !== this));
  }

  // targetIsChild(target) {
  //   const parent = this.scrollBox;
  //   if (target && parent) {
  //     while (target) {
  //       if (parent === target) {
  //         return true;
  //       }
  //       // eslint-disable-next-line no-param-reassign
  //       target = target.p了他arentNode;
  //     }
  //   }
  //   return false;
  // }

  isOnTheEdge({
    target,
    // eslint-disable-next-line no-shadow
    startY,
    moveY,
  }) {
    // eslint-disable-next-line prefer-destructuring
    const scrollBox = this.scrollBox;
    const top = scrollBox.scrollTop;
    const ch = scrollBox.clientHeight;
    const sh = scrollBox.scrollHeight;

    console.log('start record!');
    console.log({
      scrollBox,
      top,
      ch,
      sh,
    });
    // 此时滑动元素滚动到滑动框边缘
    if ((top === 0 && moveY > startY) || (top + ch === sh && moveY < startY)) {
      console.log('on edge!!!');
      return true;
    }

    console.log('record finished!!!');
    return false;
  }
}
