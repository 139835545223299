import { CircleLoading } from '#components/Loading';
import List from '#components/ScrollList';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const UnselectedItem = styled.div`
  display: flex;
  align-items: center;
  height: 68px;
  line-height: 72px;
  border-radius: 3px;
  background-color: #f8f9fc;
  margin-bottom: 2px;
  padding: 0 12px;

  &:hover {
    background-color: #dbe6ff;
    cursor: pointer;
  }
  &:last-child {
    margin-bottom: 2px;
  }
`;

const SelectedItem = styled(UnselectedItem)`
  background-color: #0564f5;
  &:hover {
    background-color: #0564f5;
    cursor: pointer;
  }
`;

const UnselectedCRMItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 44px;
  width: 44px;
  height: 44px;
  font-size: 16px;
  color: #ffffff;
  background-color: #0564f5;
  text-align: center;
  position: relative;
  ::after {
    display: ${({ showMark }) => (showMark ? 'block' : 'none')};
    content: 'CRM';
    background: #FF9B26;
    font-size: 11px;
    line-height: 16px;
    position: absolute;
    top: -5px;
    left: 22px;
    width: 34px;
    height: 16px;
    border-radius: 3px;
  }
`;

const UnselectedPRMItemIconWrapper = styled(UnselectedCRMItemIconWrapper)`
  ::after {
    content: 'PRM';
    background: #00CA8D;
  }
`;

const SelectedCRMItemIconWrapper = styled(UnselectedCRMItemIconWrapper)`
  color: #0564f5;
  background-color: #ffffff;
`;

const SelectedPRMItemIconWrapper = styled(UnselectedPRMItemIconWrapper)`
  color: #0564f5;
  background-color: #ffffff;
`;

const ItemIcon = styled.div``;

const UnselectedItemLabel = styled.div`
  flex: 1;
  word-break: break-word;
  font-size: 16px;
  color: #191f25;
  line-height: 20px;
  margin-left: 12px;
`;

const SelectedItemLabel = styled(UnselectedItemLabel)`
  color: #ffffff;
`;

const TenantLoading = styled(CircleLoading)`
  margin: 0 30px 0 auto;
  width: 18px;
  height: 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.28);
  border-right: 1px solid rgba(255, 255, 255, 0.28);
  border-bottom: 1px solid rgba(255, 255, 255, 0.28);
  border-left: 1px solid #ffffff;
`;

export default function TenantList(props) {
  const { dataList, onClick } = props;
  let showMark = false;
  for (let i = 0, len = dataList.length; i < (len - 1); i++) {
    if (dataList[i].user_type !== dataList[++i].user_type) {
      showMark = true;
    }
  }
  return (
    <List dataList={dataList}>
      {({ data }) => {
        const {
          id, company, selected, user_type: userType,
        } = data;
        const Item = selected
          ? SelectedItem
          : UnselectedItem;
        // eslint-disable-next-line no-nested-ternary
        const ItemIconWrapper = selected
          ? userType == 0
            ? SelectedCRMItemIconWrapper
            : SelectedPRMItemIconWrapper
          : userType == 0
            ? UnselectedCRMItemIconWrapper
            : UnselectedPRMItemIconWrapper;
        const ItemLabel = selected
          ? SelectedItemLabel
          : UnselectedItemLabel;
        return (
          <Item id={`tenant-${id}`} key={id} onClick={() => onClick(id)}>
            <ItemIconWrapper showMark={showMark}>
              <ItemIcon>{company[0]}</ItemIcon>
            </ItemIconWrapper>
            <ItemLabel>{company}</ItemLabel>
            {selected && <TenantLoading />}
          </Item>
        );
      }}
    </List>
  );
}

TenantList.propTypes = {
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      // icon: PropTypes.string,
      company: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};
