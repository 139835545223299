import zhCNMessages from '#resources/zh-CN.json';
import zhTWMessages from '#resources/zh-TW.json';
import enMessages from '#resources/en.json';

require('intl/locale-data/jsonp/en.js');
require('intl/locale-data/jsonp/zh.js');

export default function getAppLocale() {
  const language = navigator.language || navigator.userLanguage;
  const appLocale = {
    zh: {
      locales: {
        zh: {
          ...zhCNMessages,
        },
      },
      currentLocale: 'zh',
    },
    'zh-TW': {
      locales: {
        'zh-TW': {
          ...zhTWMessages,
        },
      },
      currentLocale: 'zh-TW',
    },
    en: {
      locales: {
        en: {
          ...enMessages,
        },
      },
      currentLocale: 'en',
    },
  };
  switch (true) {
    case /^zh/.test(language):
      // 中文语言环境，进一步区分简中还是繁中;
      switch (language) {
        case 'zh-TW':
          return appLocale['zh-TW'];
        default:
          return appLocale.zh;
      }
    case /^en/.test(language):
    default:
      return appLocale.en;
  }
}
