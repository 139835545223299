import { call, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';
import {
  setRegisterAddress,
  setRegisterAddressError,
} from '../action';
import {
  status,
  getRegisterAddressService,
  register,
} from '../LoginService';
import { store } from '../index';

export default function* jumpToRegister() {
  const { registerAddress } = store.getState();
  const res = registerAddress
    ? { scode: status.OK, result: registerAddress }
    : yield call(getRegisterAddressService);
  if (res) {
    switch (res.scode) {
      case status.OK:
        yield put(setRegisterAddress(res.result));
        yield call(register);
        break;
      default:
        yield put(setRegisterAddressError(intl.formatMessage(getScodeMessages(res.scode), {
          errorMsg: res.error_msg,
        })));
        break;
    }
  }
}
