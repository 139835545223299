import React from 'react';
import Bgmaintain from '#static/img/bg_maintain.png';

const PodRelease = () => (
  <div className="maintain">
    <div className="maintain-left">
      <img src={Bgmaintain} alt="系统维护" />
    </div>
    <div className="maintain-right">
      <p>系统正在升级维护，给您带来的不便敬请谅解！</p>
      <p>We are down for maintenance. Sorry for the inconvenience. </p>
    </div>
  </div>
);


export default PodRelease;
