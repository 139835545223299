/* eslint-disable */
import { call, put } from "redux-saga/effects";
import { store } from "../index";
import qywxFetchTenant from "./qywxFetchTenant"
import {
  seleQYWXList,
  setLoginLoading,
} from "../action";
import { jumpQYWXAuthentication } from "../LoginService"

export default function* qywxAuthen(context, action) {
  console.log(action,action.payload, '触发id');
  console.log(store.getState(), '获取store值');
  console.log(context, 'context的值')
  const id = action.payload;
  const { qywxDataList } = store.getState();
   const list = qywxDataList&&qywxDataList.find(item=>{
        return item.id===id
  })
 console.log(list, 'isMFAz值');
  // 选择租户
  yield put(seleQYWXList(id));
  if(list.isMFA === 1) {
    yield put(setLoginLoading(false));
    yield call(jumpQYWXAuthentication, context);
  } else {
    yield call(qywxFetchTenant, action);
  }
}
