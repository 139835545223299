import styled from 'styled-components';

const Img = styled.div`
  width: 100%;
  height: 100%;
  background-color: #F5F6FA;
  background-image: url('${props => props.imgSrc}');
  background-size: cover;
  background-position: center;
`;

export default Img;
