/* eslint-disable */
export function getTimestamp(msec) {
  msec = !msec && msec !== 0 ? msec : 1;
  return parseInt(new Date().valueOf() / msec, 10);
}

export function loadScript(src, cb) {
  const head = document.head || document.getElementsByTagName("head")[0];
  const script = document.createElement("script");

  cb = cb || function() {};

  script.type = "text/javascript";
  script.src = src;

  if (!("onload" in script)) {
    script.onreadystatechange = function() {
      if (this.readyState !== "complete" && this.readyState !== "loaded")
        return;
      this.onreadystatechange = null;
      cb(script);
    };
  }

  script.onload = function() {
    this.onload = null;
    cb(script);
  };

  head.appendChild(script);
}
