import { call, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';
import { defineMessages } from '#utils/i18n/intl';
import {
  loginStates,
  ResetPasswordType,
  setLoginState,
  setResetPasswordFlag,
  setPasswordRules,
  setPasswordRulesError,
  setPasswordTimes,
  setResetPasswordLoading,
} from '../action';
import { getPasswordRules, status } from '../LoginService';
import { store } from '../index';
import getSelectedTenant from '../selectors/getSelectedTenant';

const messages = defineMessages({
  passwordMaxLength: {
    id: 'PasswordRules.maxLength',
    defaultMessage: '{passwordLength}-16位',
  },
  passwordFixedLength: {
    id: 'PasswordRules.FixedLength',
    defaultMessage: '16位',
  },
  containLetters: {
    id: 'PasswordRules.containLetters',
    defaultMessage: '必须包含大小写字母',
  },
  containNumberAndLetters: {
    id: 'PasswordRules.containNumberAndLetters',
    defaultMessage: '必须包含数字和大小写字母',
  },
  containNumberAndLettersAndSpecials: {
    id: 'PasswordRules.containNumberAndLettersAndSpecials',
    defaultMessage: '必须包含数字、大小写字母和特殊字符',
  },
  differentPassword: {
    id: 'PasswordRules.differentPassword',
    defaultMessage: '新密码不能和近期用过的密码相同',
  },
  logoutSwitch: {
    id: 'PasswordRules.logoutSwitch',
    defaultMessage: '退出登录其他设备',
  },
});

export default function* jumpToResetPassword({ payload: { resetPasswordFlag } }) {
  // 清空Loading状态
  yield put(setResetPasswordLoading(false));

  // 首先设置是否是用户首次登录导致的重置页面
  yield put(setResetPasswordFlag(resetPasswordFlag));

  // 然后请求调用请求密码规则的接口
  const state = store.getState();
  const { passport: passportId } = state;
  const { id: tenantId } = getSelectedTenant(state);
  const {
    scode,
    result,
  } = yield call(getPasswordRules, { passportId, tenantId });

  switch (scode) {
    case status.OK: {
      const passwordRules = generatePasswordRules(result);
      yield put(setPasswordRules(passwordRules));
      yield put(setPasswordTimes(result.pwdUpdateAt));
      yield put(setLoginState(loginStates.RESET_PASSWORD));
      break;
    }
    default:
      yield put(setPasswordRulesError(intl.formatMessage(getScodeMessages(1000003))));
      yield put(setLoginState(loginStates.RESET_PASSWORD));
      break;
  }
}

function generatePasswordRules({
  passwordLength, containRule, diffPassportCount, logoutSwitch,
}) {
  const passwordRulesList = [];
  if (passwordLength < 16) {
    passwordRulesList.push(intl.formatMessage(messages.passwordMaxLength, { passwordLength }));
  } else {
    passwordRulesList.push(intl.formatMessage(messages.passwordFixedLength));
  }
  if (containRule !== 0) {
    switch (containRule) {
      case 1:
        passwordRulesList.push(intl.formatMessage(messages.containLetters));
        break;
      case 2:
        passwordRulesList.push(intl.formatMessage(messages.containNumberAndLetters));
        break;
      case 3:
        passwordRulesList.push(intl.formatMessage(messages.containNumberAndLettersAndSpecials));
        break;
      default:
        break;
    }
  }
  if (diffPassportCount !== 0) {
    passwordRulesList.push(intl.formatMessage(messages.differentPassword));
  }
  if (logoutSwitch && logoutSwitch !== 0) {
    passwordRulesList.push(intl.formatMessage(messages.logoutSwitch));
  }
  return passwordRulesList.join('，');
}
