import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import { get } from '#utils/globalVariable';
import { matchPath } from 'react-router-dom';

// import logoImg from '#static/img/register_logo.png';
import Center from '#components/layout/Center';
import Logo from '#components/Logo';
import { BigPromptTip, MediumPromptTip } from '#components/PromptTip';
import TenantList from './components/TenantList';
import QYWXTenantList from './components/QYWXTenantList';
import MsgModal from '#components/MsgModal';
import queryString from 'query-string';

import {
  // loginStates,
  submitTenant,
  setLoginState,
  setTenantError,
  setNetworkError,
  handleFatalError,
  changeData,
} from '#models/login/action';
import getTenantList from '#models/login/selectors/getTenantList';

const messages = defineMessages({
  welcomeBack: {
    id: 'SelectTenant.welcomeBack',
    defaultMessage: '欢 迎 !',
  },
  welcomeTip: {
    id: 'SelectTenant.welcomeTip',
    defaultMessage: '您的账号属于多个公司，请选择要登录的公司',
  },
});

const LogoWrapper = styled.div`
  padding-bottom: 64px;
`;

const WelcomeBackWrapper = styled.div`
  margin: -32px 0 12px;
`;

const WelcomeTipWrapper = styled.div`
  margin: 0 0 24px;
`;
class SelectTenant extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      tenantList,
      onSelectTenantClick,
      tenantError,
      networkError,
      fatalError,
      onCloseMsgModal,
      dataList,
      ide,
      onChangeData,
    } = this.props;
    const showHeader = !!matchPath(window.location.pathname, {
      path: '/auc/wxwork/multi-tenant',
      exact: true,
    });
    return (
      <Center
        showFooter={!showHeader}
      >
        {
          get('env') === 'WEB' && (
            <>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              <WelcomeBackWrapper>
                <BigPromptTip>
                  {intl.formatMessage(messages.welcomeBack)}
                </BigPromptTip>
              </WelcomeBackWrapper>
              <WelcomeTipWrapper>
                <MediumPromptTip>
                  {intl.formatMessage(messages.welcomeTip)}
                </MediumPromptTip>
              </WelcomeTipWrapper>
            </>
          )
        }
        {
          ide ? <QYWXTenantList dataList={dataList} onClick={onChangeData} /> : <TenantList dataList={tenantList} onClick={onSelectTenantClick} />
        }
        <MsgModal
          show={
            (!!tenantError)
            || (!!networkError)
            || (!!fatalError)
          }
          msg={
            tenantError
            || networkError
            || fatalError
          }
          onClose={onCloseMsgModal}
        />
      </Center>
    );
  }
}

SelectTenant.propTypes = {
  tenantList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: PropTypes.string,
      selected: PropTypes.bool,
      user_type: PropTypes.string,
    }),
  ).isRequired,
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: PropTypes.string,
      selected: PropTypes.bool,
      userType: PropTypes.string,
    }),
  ).isRequired,
  onSelectTenantClick: PropTypes.func.isRequired,
  onChangeData: PropTypes.func.isRequired,
  tenantError: PropTypes.string,
  networkError: PropTypes.string,
  fatalError: PropTypes.string,
  onCloseMsgModal: PropTypes.func.isRequired,
  ide: PropTypes.number,

};

SelectTenant.defaultProps = {
  tenantError: null,
  networkError: null,
  fatalError: null,
  ide: null,
};

// connect
function mapStatesToProps(state) {
  return {
    tenantList: getTenantList(state),
    tenantError: state.tenantError,
    fatalError: state.fatalError,

  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSelectTenantClick(id) {
      dispatch(submitTenant(id));
    },
    onCloseMsgModal() {
      dispatch(setTenantError(null));
      dispatch(setNetworkError(null));
      dispatch(setLoginState(null));
      dispatch(handleFatalError());
    },
    onChangeData(id) {
      dispatch(changeData(id));
    },
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps,
)(SelectTenant);
