/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { defineMessages } from '#utils/i18n/intl';
import AvatarMonitor from '#utils/sentry/AvatarMonitor';

const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  max-height: 100%;
  background-color: #FFFFFF;
  border-radius: 4px;
  text-align: center;
  overflow: auto;
`;

const Message = styled.div`
  padding: 24px;
`;

const Button = styled.button`
  min-width: 36%;
  margin: 0 auto 24px;
  padding: 3px 17px;
  background-color: ${props => props.buttonColor || '#0564f5'};
  border: none;
  border-radius: 21px;
  font-size: 16px;
  color: #fff;
  line-height: 36px;
  text-align: center;
  box-shadow: 0 4px 10px 0 rgba(79,139,243,0.30);
  &:hover {
    background-color: #2065CF;
    cursor: pointer;
  }
`;

const messages = defineMessages({
  button: {
    id: 'MsgModal.button',
    defaultMessage: '知道了',
  },
});

ReactModal.setAppElement('#modal-slot');

class MsgModal extends React.Component {

  componentDidUpdate() {
    const { show } = this.props;
    if (show) {
      AvatarMonitor.getInstance().report();
    }
  }

  render() {
    const { buttonColor, show, msg, onClose, iconSrc, iconStyle, closeWord } = this.props;
    return (
      <ReactModal
    isOpen={show}
    style={{
      overlay: {
        zIndex: 101,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      content: {
        padding: 0,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }}
  >
    <Wrapper>
      {iconSrc && <img style={iconStyle} src={iconSrc} alt="es-lint want to get" />}
      <Message>{msg}</Message>
      <Button
        buttonColor={buttonColor}
        onClick={onClose}
      >
        {closeWord || intl.formatMessage(messages.button)}
      </Button>
    </Wrapper>
  </ReactModal>
    );
  }
}

MsgModal.propTypes = {
  show: PropTypes.bool.isRequired,
  msg: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
  iconStyle: PropTypes.object,
  closeWord: PropTypes.string,
};

MsgModal.defaultProps = {
  msg: null,
  iconSrc: null,
  iconStyle: null,
  closeWord: null,
};

function mapStateToProps(state) {
  return {
    username: state.username,
    buttonColor: state.brandInfo.color,
  };
}

export default connect(mapStateToProps)(MsgModal);
