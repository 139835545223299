/* eslint-disable */
import React from "react";
import queryString from "query-string";
import intl from "react-intl-universal";
import Tips from "#components/Tips";
import getScodeMessages from "#utils/i18n/getScodeMessages";

const withTips = (Page) => (props) => {
  const { globalError } = props;
  const queryParams = queryString.parse(window.location.search);
  const notFromRefresh =
    performance.navigation.type !== performance.navigation.TYPE_RELOAD;
  const errorMsg =
    notFromRefresh && queryParams.error_code
      ? intl.formatMessage(getScodeMessages(queryParams.error_code))
      : globalError;
  return (
    <>
      {errorMsg && <Tips closeable type="error" message={errorMsg} />}
      <Page {...props} />
    </>
  );
};

export default withTips;
