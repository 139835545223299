import { createSelector } from 'reselect';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import { ResetPasswordType } from '../action';

const messages = defineMessages({
  firstLogin: {
    id: 'ResetPassword.firstLogin',
    defaultMessage: '初次登录，请设置您的登录密码',
  },
  changedPasswordRules: {
    id: 'ResetPassword.changedPasswordRules',
    defaultMessage: '密码规则修改，请设置新密码。',
  },
  passwordExpired: {
    id: 'ResetPassword.passwordExpired',
    defaultMessage: '您的密码已经过期，请设置新的密码。',
  },
  forgetPassword: {
    id: 'ResetPassword.forgetPassword',
    defaultMessage: '请设置新的登录密码',
  },
});
const getResetPasswordFlag = state => state.resetPasswordFlag;

export default createSelector(
  getResetPasswordFlag,
  (resetPasswordFlag) => {
    switch (resetPasswordFlag) {
      case ResetPasswordType.FIRST_LOGIN:
        return intl.formatMessage(messages.firstLogin);
      case ResetPasswordType.RULES_CHANGED:
        return intl.formatMessage(messages.changedPasswordRules);
      case ResetPasswordType.PASSWORD_EXPIRED:
        return intl.formatMessage(messages.passwordExpired);
      case ResetPasswordType.FORGET_PASSWORD:
        return intl.formatMessage(messages.forgetPassword);
      default:
        return intl.formatMessage(messages.forgetPassword);
    }
  },
);
